import React, { useRef } from "react";
import { Link } from "react-router-dom";
import img1 from "../../../Assets/Images/d-markerting-bnr.png";
import img2 from "../../../Assets/Images/digitalmask.png";
import img3 from "../../../Assets/Images/digitalmarkser-1.svg";
import img4 from "../../../Assets/Images/digitalmarkser-2.svg";
import img5 from "../../../Assets/Images/digitalmarkser-3.svg";
import Happyclient from "../../../GraphicTemplate/HappyClient/Happyclient";
import { motion, useAnimationFrame } from "framer-motion";
import PWC from "../PortfolioWhyChoose/PWC";

import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.min.css";
import ScheduleFreeConsult from "../../../ShareButton/ScheduleFreeConsult";

const DigitalHeader = () => {
  const ref = useRef(null);
  useAnimationFrame((t) => {
    const y = (1 + Math.sin(t / 500)) * -20;
    ref.current.style.transform = `translateY(${y}px)`;
  });
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div>
      <section class="inner-banner-sec">
        <div class="container">
          <div class="inner-banner-row">
            <div class="inner-banner-col1">
              <motion.h1
                initial={{ x: -400, opacity: "0" }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.6 }}
              >
                Hire the Top Digital Marketing Agency
              </motion.h1>
              <motion.p
                initial={{ x: -400, opacity: "0" }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.9 }}
              >
                At Teqnite Digital Marketing Agency, we talk and persevere about
                measurable success to amplify your brand image — and,
                consecutively, blow past your competition. Your business needs
                results, and we've got just THAT for you. Why wait? Supercharge
                your online presence and hit those success milestones loud and
                clear!
              </motion.p>
              <motion.p
                initial={{ x: -400, opacity: "0" }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.9 }}
              >
                Our custom digital marketing strategy will give your brand the
                boost it deserves!
              </motion.p>
              <motion.div
                initial={{ x: -400, opacity: "0" }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 1.3 }}
              >
                <ScheduleFreeConsult />
              </motion.div>
            </div>
            <motion.div
              class="inner-banner-col2"
              drag
              dragConstraints={{ left: 10, top: 10, right: 10, bottom: 10 }}
              style={{ cursor: "pointer" }}
              ref={ref}
            >
              <img src={img1} alt="" />
            </motion.div>
          </div>
        </div>
      </section>
      <Happyclient />
      <section class="mask-details-sec">
        <div class="container">
          <div class="mast-details-row">
            <div class="mast-details-col1">
              <ScrollAnimation animateIn="fadeIn">
                <img src={img2} alt="" />
              </ScrollAnimation>
            </div>
            <div class="mast-details-col2">
              <ScrollAnimation animateIn="fadeIn">
                <h2>Let Us Fuel the Growth of Your Company!</h2>
              </ScrollAnimation>
              <ScrollAnimation animateIn="fadeIn">
                <p>
                  Our comprehensive digital marketing services are strategically
                  crafted to fuel the growth of your company. Whether you're a
                  startup establishing a digital presence or an established
                  enterprise aiming to expand, our results-driven approach
                  encompasses SEO optimization, social media management, and
                  targeted digital marketing campaigns. Why compromise for the
                  ordinary when you can get the best of everything under one
                  banner? Stand out in the digital crowd as new heights await
                  you.
                </p>
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </section>
      <section class="offers-sec">
        <div class="container">
          <div class="offers-sec-title">
            <ScrollAnimation animateIn="fadeIn">
              <h2>Our Digital Marketing Services</h2>
              <p>
                We are a digital marketing company with a strategic plan that's
                proprietary. We follow a holistic approach to building your
                business reputation online. While you focus on your business, we
                handle the rest for you. Take your online game to the next
                level? Dive into Teqnite's digital marketing solutions.
              </p>
            </ScrollAnimation>
          </div>
          <div class="offers-sec-grid">
            <div class="s-offer-cell">
              <ScrollAnimation animateIn="fadeIn">
                <div class="s-offer-name">
                  <div class="s-offer-ico">
                    <img src={img3} alt="" />
                  </div>
                  <h3>SEO Services</h3>
                </div>
                <p>
                  Teqnite's SEO services equip your business with exceptional
                  online visibility. Our expert strategies drive organic
                  traffic, enhance search engine rankings, and maximize ROI. We
                  take care of your strategic keyword research and on/off-page
                  optimization. Ensuring your website stands out and shines in
                  search engine results is our ultimate goal. Enhance your
                  digital presence with Teqnite SEO.
                </p>
              </ScrollAnimation>
            </div>
            <div class="s-offer-cell">
              <ScrollAnimation animateIn="fadeIn">
                <div class="s-offer-name">
                  <div class="s-offer-ico">
                    <img src={img4} alt="" />
                  </div>
                  <h3>Google Ads And Facebook Ads</h3>
                </div>
                <p>
                  Tired of lackluster ads? Elevate your brand with Teqnite's
                  winning Google & Facebook ad campaigns. We laser-focus on your
                  ideal audience, crafting captivating creatives that drive
                  massive engagement. Dominate the Google network or conquer
                  Facebook; we'll help you achieve your goals, skyrocket
                  conversions, amplify brand awareness, and deliver results you
                  can measure. Ready to see the difference? Contact Teqnite
                  today!
                </p>
              </ScrollAnimation>
            </div>
            <div class="s-offer-cell">
              <ScrollAnimation animateIn="fadeIn">
                <div class="s-offer-name">
                  <div class="s-offer-ico">
                    <img src={img5} alt="" />
                  </div>
                  <h3>Social Media Marketing</h3>
                </div>
                <p>
                  Forget just existing online; build lasting relationships with
                  your audience with Teqnite's social media magic. We create
                  more than just posts - we craft compelling, interactive
                  experiences that spark conversations and grow your brand
                  community. Our engagement strategies work in alignment with
                  content that resonates with your brand message, establishing a
                  seamless brand narrative.
                </p>
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </section>
      <section className="hire-me-sec">
        <div className="container">
          <ScrollAnimation animateIn="fadeIn">
            <div className="hire-me-block">
              <div className="hire-me-cntnt">
                <div className="hm-text">
                  <h2>Boost Your Online Presence Now!</h2>
                </div>
                <div className="hm-cta">
                  <Link to="/contact-us" onClick={scrollToTop}>
                    Get In Touch
                  </Link>
                </div>
              </div>
            </div>
          </ScrollAnimation>
        </div>
      </section>
      <PWC />
    </div>
  );
};

export default DigitalHeader;
