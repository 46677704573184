import React, { useRef } from "react";
import { Link } from "react-router-dom";
import img1 from "../../../Assets/Images/2d-anim-banner.png";
import img2 from "../../../Assets/Images/2d3dmask.png";
import img3 from "../../../Assets/Images/2d3dser-1.svg";
import img4 from "../../../Assets/Images/2d3dser-2.svg";
import img5 from "../../../Assets/Images/2d3dser-3.svg";
import Happyclient from "../../../GraphicTemplate/HappyClient/Happyclient";
import { motion } from "framer-motion";
import { useAnimationFrame } from "framer-motion";
// import Tabs from "../2d3DTabs/Tabs";
import TabsWhycho0se from "../2D3DWCT/TabsWhycho0se";
import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.min.css";
import ScheduleFreeConsult from "../../../ShareButton/ScheduleFreeConsult";

const Header = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const ref = useRef(null);
  useAnimationFrame((t) => {
    const y = (1 + Math.sin(t / 500)) * -20;
    ref.current.style.transform = `translateY(${y}px)`;
  });
  return (
    <div>
      <section className="inner-banner-sec">
        <div className="container">
          <div className="inner-banner-row">
            <div className="inner-banner-col1">
              <motion.h1
                initial={{ x: -400, opacity: "0" }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.6 }}
              >
                2D/3D Video Animation Services: Ignite Your Visual Storytelling
              </motion.h1>
              <motion.p
                initial={{ x: -400, opacity: "0" }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.9 }}
              >
                Welcome to Teqnite Animation Studio, where we bring your ideas
                to life in stunning 2D and 3D animation. We breathe life into
                your concepts, weaving them into compelling, captivating
                narratives that resonate with your audience.
              </motion.p>
              <motion.div
                initial={{ x: -400, opacity: "0" }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 1.3 }}
              >
                <ScheduleFreeConsult />
              </motion.div>
            </div>
            <motion.div
              className="inner-banner-col2"
              drag
              dragConstraints={{ left: 10, top: 10, right: 10, bottom: 10 }}
              style={{ cursor: "pointer" }}
              ref={ref}
            >
              <img src={img1} alt="" />
            </motion.div>
          </div>
        </div>
      </section>

      <Happyclient />

      <section className="mask-details-sec PB5">
        <div className="container">
          <div className="mast-details-row">
            <div className="mast-details-col1">
              <ScrollAnimation animateIn="fadeIn">
                <img src={img2} alt="" />
              </ScrollAnimation>
            </div>
            <div className="mast-details-col2">
              <ScrollAnimation animateIn="fadeIn">
                <h2>Embrace The Power Of 2D And 3D Animation</h2>
              </ScrollAnimation>
              <ScrollAnimation animateIn="fadeIn">
                <p>
                  So, you want to breathe life into your brand, captivate
                  audiences, and stand out. Experience the transformative power
                  of Teqnite's 2D and 3D animation. Our animations infuse
                  vitality into your concepts, revolutionizing storytelling for
                  dynamic engagement. In marketing, these animations transcend
                  norms, offering captivating product animations, simplified
                  explanations for complex ideas, and lasting impressions on
                  audiences.
                </p>
                <p>
                  Harnessing the merger of creativity and technology, our
                  animations craft immersive experiences that echo your brand's
                  narrative. Elevate your visual storytelling with Teqnite's
                  pioneering expertise in 2D and 3D animation—where innovation
                  ignites the imagination.
                </p>
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </section>

      <section className="offers-sec">
        <div className="container">
          <div className="offers-sec-title">
            <ScrollAnimation animateIn="fadeIn">
              <h2>2D, 3D, And Video Animation Services</h2>
              <p>
                Dive deep, soar high: Witness magic with Teqnite's Animation
                services.
              </p>
            </ScrollAnimation>
          </div>
          <div className="offers-sec-grid">
            <div className="s-offer-cell">
              <ScrollAnimation animateIn="fadeIn">
                <div className="s-offer-name">
                  <div className="s-offer-ico">
                    <img src={img3} alt="" />
                  </div>
                  <h3>2d,3d Animation</h3>
                </div>
                <p>
                  Get the fusion of creativity and precision with Teqnite's
                  Animation Services. With our 2D Animation Services, we turn
                  concepts into captivating visual narratives that captivate and
                  endure. Step into the future with our 3D Animation Services,
                  where expert animators breathe life into ideas with unmatched
                  depth, be it for product representation, architectural tours,
                  or cinematic marvels.
                </p>
              </ScrollAnimation>
            </div>

            <div className="s-offer-cell">
              <ScrollAnimation animateIn="fadeIn">
                <div className="s-offer-name">
                  <div className="s-offer-ico">
                    <img src={img4} alt="" />
                  </div>
                  <h3>Whiteboard Animation</h3>
                </div>
                <p>
                  Turn your concepts into captivating tales with our Whiteboard
                  Animation Services. Utilizing the whiteboard canvas, our adept
                  animators simplify and clarify ideas, rendering intricate
                  information engaging and readily understandable. Amplify your
                  message through this powerful and visually stimulating
                  storytelling medium.
                </p>
              </ScrollAnimation>
            </div>
            <div className="s-offer-cell">
              <ScrollAnimation animateIn="fadeIn">
                <div className="s-offer-name">
                  <div className="s-offer-ico">
                    <img src={img5} alt="" />
                  </div>
                  <h3>Explainer Animation</h3>
                </div>
                <p>
                  Teqnite's Explainer Animation Services demystify complexity.
                  Our adept animators produce visually captivating animations
                  that distill intricate concepts into easily digestible and
                  compelling narratives. Whether unveiling a product, service,
                  or idea, our Explainer Animation Services enthrall your
                  audience, leaving an indelible mark.
                </p>
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </section>
      <section className="hire-me-sec">
        <div className="container">
          <ScrollAnimation animateIn="fadeIn">
            <div className="hire-me-block">
              <div className="hire-me-cntnt">
                <div className="hm-text">
                  <h2>Bring Your Ideas to Life!</h2>
                </div>
                <div className="hm-cta">
                  <Link to="/contact-us" onClick={scrollToTop}>
                    Get In Touch
                  </Link>
                </div>
              </div>
            </div>
          </ScrollAnimation>
        </div>
      </section>
      {/*<Tabs />*/}
      <TabsWhycho0se />
    </div>
  );
};

export default Header;
