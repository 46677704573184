import img1 from "../../../Assets/Images/blg-01.jpg";
const BlogData = [
  {
    id: 1,
    Date: "Dec 12, 2023",
    Title: "What Does a Digital Marketing Company Do?",
    Discriptions:
      "What an awesome experience ! Working with Teqnite was an awesome and simple experience. We worked through few revisions and Tyler was always calm and encouraging- even if i had negative feedback to some of the design concepts. I was made comfortable and did not feel rushed through the process. I would happily recommend them for logo design. In the end- I have a perfect logo for my business What an awesome experience ! Working with Teqnite was an awesome and simple experience. We worked through few revisions and Tyler was always calm and encouraging- even if i had negative feedback to some of the design concepts. I was made comfortable and did not feel rushed through the process. I would happily recommend them for logo design. In the end- I have a perfect logo for my business What an awesome experience ! Working with Teqnite was an awesome and simple experience. We worked through few revisions and Tyler was always calm and encouraging- even if i had negative feedback to some of the design concepts. I was made comfortable and did not feel rushed through the process. I would happily recommend them for logo design. In the end- I have a perfect logo for my business What an awesome experience ! Working with Teqnite was an awesome and simple experience. We worked through few revisions and Tyler was always calm and encouraging- even if i had negative feedback to some of the design concepts. I was made comfortable and did not feel rushed through the process. I would happily recommend them for logo design. In the end- I have a perfect logo for my business.",
    Img: img1,
  },
  {
    id: 2,
    Date: "Dec 12, 2023",
    Title: "What Does a Development Company?",
    Discriptions:
      "What an awesome experience ! Working with Teqnite was an awesome and simple experience. We worked through few revisions and Tyler was always calm and encouraging- even if i had negative feedback to some of the design concepts. I was made comfortable and did not feel rushed through the process. I would happily recommend them for logo design. In the end- I have a perfect logo for my business .",
    Img: img1,
  },
  {
    id: 3,
    Date: "Dec 12, 2023",
    Title: "What Does a Digital Marketing Company Do?",
    Discriptions:
      "What an awesome experience ! Working with Teqnite was an awesome and simple experience. We worked through few revisions and Tyler was always calm and encouraging- even if i had negative feedback to some of the design concepts. I was made comfortable and did not feel rushed through the process. I would happily recommend them for logo design. In the end- I have a perfect logo for my business What an awesome experience ! Working with Teqnite was an awesome and simple experience. We worked through few revisions and Tyler was always calm and encouraging- even if i had negative feedback to some of the design concepts. I was made comfortable and did not feel rushed through the process. I would happily recommend them for logo design. In the end- I have a perfect logo for my business .",
    Img: img1,
  },
];
export default BlogData;
