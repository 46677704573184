import React from "react";
import AboutMain from "../AboutMain/AboutMain";
import GrowYourBusiness from "../../../Growbussnies/GrowYourBusiness";
import Testimonial from "../../../Testimonial/Testimonial";
import { Helmet } from "react-helmet";
const Index = () => {
  return (
    <div>
      <Helmet>
        <title>About Us | Teqnite®</title>
        <meta
          name="description"
          content="Unlock digital success with Teqnite®, your premier marketing ally. Innovate with us. Contact info@teqnite.com, call 022-6119490."
        />
        <meta
          name="keywords"
          content="Digital marketing services, Digital marketing agency, Internet marketing agency, Digital marketing strategies and campaigns, Digital marketing solutions"
        />
      </Helmet>
      <AboutMain />
      <GrowYourBusiness />
      <Testimonial />
    </div>
  );
};

export default Index;
