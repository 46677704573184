import React from "react";
import DigitalHeader from "../DigitalPage/DigitalHeader";
// import IndexTabs from "../../../GraphicTemplate/DesignTabs/IndexTabs";
// import WhyChooseTeqnite from "../../../GraphicTemplate/WhyChooseTeqnite/WhyChooseTeqnite";
import GrowYourBusiness from "../../../Growbussnies/GrowYourBusiness";
import Testimonial from "../../../Testimonial/Testimonial";
import { Helmet } from "react-helmet";

const Index = () => {
  return (
    <div>
      <Helmet>
        <title>Digital Marketing Services: SEO, Google Ads & More!</title>
        <meta
          name="description"
          content="Boost your online visibility with our digital marketing services. From SEO to Google Ads, we elevate your brand's digital presence for maximum success."
        />
        <meta
          name="keywords"
          content="digital marketing services, digital marketing company, digital marketing solutions, digital marketing agency, top digital marketing agency, internet marketing services, digital marketing strategy, digital marketing campaign"
        />
      </Helmet>
      <DigitalHeader />
      <GrowYourBusiness />
      <Testimonial />
    </div>
  );
};

export default Index;
