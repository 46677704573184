import React, { useRef } from "react";
import img1 from "../../../Assets/Images/web-dev-bnr-col-min.png";
import img2 from "../../../Assets/Images/webmask.png";
import img3 from "../../../Assets/Images/webdev-ser-1.svg";
import img4 from "../../../Assets/Images/webdev-ser-2.svg";
import img5 from "../../../Assets/Images/webdev-ser-3.svg";

import { Link } from "react-router-dom";
import Happyclient from "../../../GraphicTemplate/HappyClient/Happyclient";
import Technologies from "../../AppDevelopment/Technologies/Technologies";
import { motion, useAnimationFrame } from "framer-motion";
import WDTabs from "../WebDesignTabs/WDTabs";
import PortfolioWhychoose from "../PortfolioWhy/PortfolioWhychoose";
import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.min.css";
import ScheduleFreeConsult from "../../../ShareButton/ScheduleFreeConsult";

const WebHeader = () => {
  const ref = useRef(null);
  useAnimationFrame((t) => {
    const y = (1 + Math.sin(t / 500)) * -20;
    ref.current.style.transform = `translateY(${y}px)`;
  });
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div>
      <section className="inner-banner-sec">
        <div className="container">
          <div className="inner-banner-row">
            <div className="inner-banner-col1">
              <motion.h1
                initial={{ x: -400, opacity: "0" }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.6 }}
              >
                Custom Web Design And Development Services
              </motion.h1>
              <motion.p
                initial={{ x: -400, opacity: "0" }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.9 }}
              >
                Enhance your digital footprint with Teqnite's exceptional
                services in Modern Web Design and Development. Our expertise
                extends to crafting websites that not only look impressive but
                also deliver a good user experience.
              </motion.p>
              {/* <motion.p
                initial={{ x: -400, opacity: "0" }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.9 }}
              >
                Let's craft a website that stands out. Get started now!
              </motion.p> */}
              <motion.div
                initial={{ x: -400, opacity: "0" }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 1.3 }}
              >
                <ScheduleFreeConsult />
              </motion.div>
            </div>
            <motion.div
              className="inner-banner-col2"
              drag
              dragConstraints={{ left: 10, top: 10, right: 10, bottom: 10 }}
              style={{ cursor: "pointer" }}
              ref={ref}
            >
              <img src={img1} alt="" />
            </motion.div>
          </div>
        </div>
      </section>

      <Happyclient />

      <section className="mask-details-sec">
        <div className="container">
          <div className="mast-details-row">
            <div className="mast-details-col1">
              <ScrollAnimation animateIn="fadeIn">
                <img src={img2} alt="" />
              </ScrollAnimation>
            </div>
            <div className="mast-details-col2">
              <ScrollAnimation animateIn="fadeIn">
                <h2>Strategic Web Design Services Online</h2>
              </ScrollAnimation>
              <ScrollAnimation animateIn="fadeIn">
                <p>
                  At Teqnite, we craft strategic web solutions that take your
                  brand to the next level. Our creative Web Design and
                  Development services are more than just making things look
                  good – we're all about creating websites that perfectly align
                  with your business goals. Whether you're looking for a
                  visually stunning showcase or a powerful e-commerce platform,
                  our team combines creativity with technical know-how to make
                  sure your online presence not only grabs attention but also
                  gets the job done. Let's work hand-in-hand and turn your web
                  vision into a digital reality.
                </p>
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </section>
      <Technologies />

      <section className="offers-sec">
        <div className="container">
          <div className="offers-sec-title">
            <ScrollAnimation animateIn="fadeIn">
              <h2>Responsive Web Design And Development Services</h2>
              <p>
                We ensure strict adherence to a well-defined set of guidelines,
                enabling us to deliver top-notch, affordable web design services
                that yield optimal results.
              </p>
            </ScrollAnimation>
          </div>
          <div className="offers-sec-grid">
            <div className="s-offer-cell">
              <ScrollAnimation animateIn="fadeIn">
                <div className="s-offer-name">
                  <div className="s-offer-ico">
                    <img src={img3} alt="" />
                  </div>
                  <h3>CMS Development</h3>
                </div>
                <p>
                  Are you looking for a robust content management system (CMS)
                  that empowers you to control and manage your website
                  effortlessly? The answer is Teqnite's CMS Development
                  Services. Our team specializes in creating brilliant,
                  user-friendly interfaces and scalable solutions, allowing you
                  to effectively manage content for any type of website, be it a
                  dynamic blog or a robust e-commerce platform.
                </p>
              </ScrollAnimation>
            </div>
            <div className="s-offer-cell">
              <ScrollAnimation animateIn="fadeIn">
                <div className="s-offer-name">
                  <div className="s-offer-ico">
                    <img src={img4} alt="" />
                  </div>
                  <h3>E-Commerce Development</h3>
                </div>
                <p>
                  Are you in search of a secure online shopping experience for
                  your customers? Look no further than Teqnite's specialized
                  E-commerce Design and Development services. We provide
                  E-commerce solutions that prioritize intuitive navigation and
                  robust payment gateways, ensuring a user-friendly and
                  trustworthy platform. Whether you're launching a new online
                  store or looking to enhance an existing one, our E-commerce
                  Development services pave the way for success in the digital
                  marketplace.
                </p>
              </ScrollAnimation>
            </div>
            <div className="s-offer-cell">
              <ScrollAnimation animateIn="fadeIn">
                <div className="s-offer-name">
                  <div className="s-offer-ico">
                    <img src={img5} alt="" />
                  </div>
                  <h3>Magento Development</h3>
                </div>
                <p>
                  Teqnite specializes in Magento Development, delivering
                  exceptional results for businesses like yours. We're talking
                  robust and scalable solutions for your online stores. With
                  customized themes, seamless integration, and performance
                  optimization, we ensure a top-notch E-commerce experience for
                  your customers. Let our expertise in Magento development
                  elevate your digital storefront and take your online business
                  to new heights. Partner with us today for a successful and
                  thriving E-commerce venture.
                </p>
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </section>
      <section className="hire-me-sec">
        <div className="container">
          <ScrollAnimation animateIn="fadeIn">
            <div className="hire-me-block">
              <div className="hire-me-cntnt">
                <div className="hm-text">
                  <h2>Turn Clicks into Sales!</h2>
                </div>
                <div className="hm-cta">
                  <Link to="/contact-us" onClick={scrollToTop}>
                    Get In Touch
                  </Link>
                </div>
              </div>
            </div>
          </ScrollAnimation>
        </div>
      </section>
      <WDTabs />
      <PortfolioWhychoose />
    </div>
  );
};

export default WebHeader;
