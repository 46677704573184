import React from "react";
import ContactMain from "../ContactMain/ContactMain";
import OurOffice from "../../../Office/OurOffice";
import { Helmet } from "react-helmet";

const Index = () => {
  return (
    <div className="contact_us">
      <Helmet>
        <title>Contact Us | Teqnite®</title>
        <meta
          name="description"
          content="Get in touch with Teqnite for expert digital marketing, website development, and logo design services. Reach out today to start transforming your online presence."
        />
        <meta name="keywords" content="contact Us" />
      </Helmet>
      <ContactMain />
      <OurOffice />
    </div>
  );
};

export default Index;
