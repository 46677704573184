import React, { useEffect } from "react";
import img1 from "../../Assets/Images/grp-proce-tabs-1.svg";
import img2 from "../../Assets/Images/grp-proce-tabs-2.svg";
import img3 from "../../Assets/Images/grp-proce-tabs-3.svg";
import img4 from "../../Assets/Images/grp-proce-tabs-4.svg";
import tabsimg1 from "../../Assets/Images/p-tab-1.jpg";
import tabsimg2 from "../../Assets/Images/p-tab-2.png";
import tabsimg3 from "../../Assets/Images/p-tab-3.png";
import tabsimg4 from "../../Assets/Images/p-tab-4.png";
const IndexTabs = () => {
  useEffect(() => {
    const tabsNav = document.querySelectorAll(".process-tab-btns ul li");
    const tabsContent = document.querySelectorAll(
      ".process-tabs-cntnt .p-tab-cntnt"
    );

    tabsNav.forEach((items, ind) => {
      items.addEventListener("click", () => {
        tabsNav.forEach((remove) => {
          remove.classList.remove("active");
        });

        items.classList.add("active");

        tabsContent.forEach((content) => {
          content.classList.remove("active");
        });

        tabsContent[ind].classList.add("active");
      });
    });
  }, []);

  return (
    <div>
      <section className="process-tabs-sec">
        <div className="container">
          <div className="process-sec-title">
            <h2>Our Graphic Design Process</h2>
            <p>
              We stick to a set of guidelines carefully crafted to deliver
              optimal design solutions and exceptional outcomes.
            </p>
          </div>
          <div className="process-sec-cntnt">
            <div className="process-tab-btns">
              <ul>
                <li className="active">
                  <div className="p-tab-ico">
                    <img src={img1} alt="" />
                  </div>
                  <span>Collecting Requirements</span>
                </li>
                <li>
                  <div className="p-tab-ico">
                    <img src={img2} alt="" />
                  </div>
                  <span>Initial Design</span>
                </li>
                <li>
                  <div className="p-tab-ico">
                    <img src={img3} alt="" />
                  </div>
                  <span>Designing</span>
                </li>
                <li>
                  <div className="p-tab-ico">
                    <img src={img4} alt="" />
                  </div>
                  <span>Satisfactory Delivery</span>
                </li>
              </ul>
            </div>
            <div className="process-tabs-cntnt">
              <div className="p-tab-cntnt active">
                <div className="p-tab-c1">
                  <span>01</span>
                  <p>
                    Firstly, we dive deep into knowing and understanding your
                    vision, preferences, and brand identity. Our goal is to
                    gather comprehensive insights to inform the creative
                    direction and ensure our designs align seamlessly with your
                    objectives.
                  </p>
                </div>
                <div className="p-tab-c2">
                  <img src={tabsimg1} alt="" />
                </div>
              </div>
              <div className="p-tab-cntnt">
                <div className="p-tab-c1">
                  <span>02</span>
                  <p>
                    In the Initial Design phase, our skilled team translates
                    your vision into preliminary design concepts. These initial
                    drafts serve as the canvas for creative exploration,
                    allowing us to present visual representations that capture
                    the essence of your brand. This stage is pivotal in shaping
                    the direction of our design journey.
                  </p>
                </div>
                <div className="p-tab-c2">
                  <img src={tabsimg2} alt="" />
                </div>
              </div>
              <div className="p-tab-cntnt">
                <div className="p-tab-c1">
                  <span>03</span>
                  <p>
                    As we enter the Designing phase, our team meticulously
                    refines the chosen concept from the initial designs.
                    Leveraging creativity and precision, we bring your vision to
                    life, ensuring each element aligns seamlessly with your
                    brand identity. This phase marks the transformation of ideas
                    into tangible and visually compelling designs.
                  </p>
                </div>
                <div className="p-tab-c2">
                  <img src={tabsimg3} alt="" />
                </div>
              </div>
              <div className="p-tab-cntnt">
                <div className="p-tab-c1">
                  <span>04</span>
                  <p>
                    In this concluding phase, we present the finalized designs
                    with meticulous attention to detail. Our commitment to your
                    satisfaction is unwavering, and we ensure that the delivered
                    designs not only meet but exceed your expectations. Your
                    vision is now a tangible reality, and our goal is your
                    complete satisfaction with the end result.
                  </p>
                </div>
                <div className="p-tab-c2">
                  <img src={tabsimg4} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default IndexTabs;
