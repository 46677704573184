import React, { useEffect, useRef } from "react";
import img from "../../../Assets/Images/projects-bnr-min.png";
import { Link } from "react-router-dom";
import { motion, useAnimationFrame } from "framer-motion";

import imgtab1 from "../../../Assets/Images/project-icon-1.svg";
import imgtab2 from "../../../Assets/Images/project-icon-2.svg";
import imgtab3 from "../../../Assets/Images/project-icon-3.svg";
import imgtab4 from "../../../Assets/Images/project-icon-4.svg";

// import gpi1 from "../../../Assets/Images/GraphicPortfolio/port-logo-1.jpg";
// import gpi2 from "../../../Assets/Images/GraphicPortfolio/port-logo-2.jpg";
// import gpi3 from "../../../Assets/Images/GraphicPortfolio/port-logo-3.jpg";
// import gpi4 from "../../../Assets/Images/GraphicPortfolio/port-logo-4.jpg";
// import gpi5 from "../../../Assets/Images/GraphicPortfolio/port-logo-6.jpg";
// import gpi6 from "../../../Assets/Images/GraphicPortfolio/port-logo-6.jpg";
// import gpi7 from "../../../Assets/Images/GraphicPortfolio/port-logo-7.jpg";
// import gpi8 from "../../../Assets/Images/GraphicPortfolio/port-logo-8.jpg";
// import gpi9 from "../../../Assets/Images/GraphicPortfolio/port-logo-9.jpg";
// import gpi10 from "../../../Assets/Images/GraphicPortfolio/port-logo-10.jpg";
// import gpi11 from "../../../Assets/Images/GraphicPortfolio/port-logo-11.jpg";
// import gpi12 from "../../../Assets/Images/GraphicPortfolio/port-logo-12.jpg";
// import gpi13 from "../../../Assets/Images/GraphicPortfolio/port-logo-13.jpg";
// import gpi14 from "../../../Assets/Images/GraphicPortfolio/port-logo-14.jpg";
// import gpi15 from "../../../Assets/Images/GraphicPortfolio/port-logo-15.jpg";
// import gpi16 from "../../../Assets/Images/GraphicPortfolio/port-logo-16.jpg";
import gpi17 from "../../../Assets/Images/GraphicPortfolio/port-logo-17.jpg";
import gpi18 from "../../../Assets/Images/GraphicPortfolio/port-logo-18.jpg";
import gpi19 from "../../../Assets/Images/GraphicPortfolio/port-logo-19.jpg";
import gpi20 from "../../../Assets/Images/GraphicPortfolio/port-logo-20.jpg";
import gpi21 from "../../../Assets/Images/GraphicPortfolio/port-logo-21.jpg";
import gpi22 from "../../../Assets/Images/GraphicPortfolio/port-logo-22.jpg";
import gpi23 from "../../../Assets/Images/GraphicPortfolio/port-logo-23.jpg";
import gpi24 from "../../../Assets/Images/GraphicPortfolio/port-logo-24.jpg";
import gpi25 from "../../../Assets/Images/GraphicPortfolio/port-logo-25.jpg";
import gpi26 from "../../../Assets/Images/GraphicPortfolio/port-logo-26.jpg";
import gpi27 from "../../../Assets/Images/GraphicPortfolio/port-logo-27.jpg";
import gpi28 from "../../../Assets/Images/GraphicPortfolio/port-logo-28.jpg";
import gpi29 from "../../../Assets/Images/GraphicPortfolio/port-logo-29.jpg";
import gpi30 from "../../../Assets/Images/GraphicPortfolio/port-logo-30.jpg";
import gpi31 from "../../../Assets/Images/GraphicPortfolio/port-logo-31.jpg";
import gpi32 from "../../../Assets/Images/GraphicPortfolio/port-logo-32.jpg";

// import wpi1 from "../../../Assets/Images/WebPortfolio/web-port-1.jpg";
// import wpi2 from "../../../Assets/Images/WebPortfolio/web-port-2.jpg";
// import wpi3 from "../../../Assets/Images/WebPortfolio/web-port-3.jpg";
// import wpi4 from "../../../Assets/Images/WebPortfolio/web-port-4.jpg";
// import wpi5 from "../../../Assets/Images/WebPortfolio/web-port-5.jpg";
// import wpi6 from "../../../Assets/Images/WebPortfolio/web-port-6.jpg";
// import wpi7 from "../../../Assets/Images/WebPortfolio/web-port-7.jpg";
// import wpi8 from "../../../Assets/Images/WebPortfolio/web-port-8.jpg";
// import wpi9 from "../../../Assets/Images/WebPortfolio/web-port-9.jpg";
// import wpi10 from "../../../Assets/Images/WebPortfolio/web-port-10.jpg";
// import wpi11 from "../../../Assets/Images/WebPortfolio/web-port-11.jpg";
// import wpi12 from "../../../Assets/Images/WebPortfolio/web-port-12.jpg";
// import wpi13 from "../../../Assets/Images/WebPortfolio/web-port-13.jpg";
// import wpi14 from "../../../Assets/Images/WebPortfolio/web-port-14.jpg";
// import wpi15 from "../../../Assets/Images/WebPortfolio/web-port-15.jpg";
// import wpi16 from "../../../Assets/Images/WebPortfolio/web-port-16.jpg";
import wpi17 from "../../../Assets/Images/WebPortfolio/web-port-17.jpg";
import wpi18 from "../../../Assets/Images/WebPortfolio/web-port-18.jpg";
import wpi19 from "../../../Assets/Images/WebPortfolio/web-port-19.jpg";
import wpi20 from "../../../Assets/Images/WebPortfolio/web-port-20.jpg";
import wpi21 from "../../../Assets/Images/WebPortfolio/web-port-21.jpg";
import wpi22 from "../../../Assets/Images/WebPortfolio/web-port-22.jpg";
import wpi23 from "../../../Assets/Images/WebPortfolio/web-port-23.jpg";
import wpi24 from "../../../Assets/Images/WebPortfolio/web-port-24.jpg";
import wpi25 from "../../../Assets/Images/WebPortfolio/web-port-25.jpg";
import wpi26 from "../../../Assets/Images/WebPortfolio/web-port-26.jpg";
import wpi27 from "../../../Assets/Images/WebPortfolio/web-port-27.jpg";
import wpi28 from "../../../Assets/Images/WebPortfolio/web-port-28.jpg";
import wpi29 from "../../../Assets/Images/WebPortfolio/web-port-29.jpg";
import wpi30 from "../../../Assets/Images/WebPortfolio/web-port-30.jpg";
import wpi31 from "../../../Assets/Images/WebPortfolio/web-port-31.jpg";
import wpi32 from "../../../Assets/Images/WebPortfolio/web-port-32.jpg";
import wpi33 from "../../../Assets/Images/WebPortfolio/web-port-33.jpg";
import wpi34 from "../../../Assets/Images/WebPortfolio/web-port-34.jpg";
import wpi35 from "../../../Assets/Images/WebPortfolio/web-port-35.jpg";
import wpi36 from "../../../Assets/Images/WebPortfolio/web-port-36.jpg";
import wpi37 from "../../../Assets/Images/WebPortfolio/web-port-37.jpg";
import wpi38 from "../../../Assets/Images/WebPortfolio/web-port-38.jpg";
import wpi39 from "../../../Assets/Images/WebPortfolio/web-port-39.jpg";
import wpi40 from "../../../Assets/Images/WebPortfolio/web-port-40.jpg";

import bpi1 from "../../../Assets/Images/BrandingPortfolio/branding-port-11.jpg";
import bpi2 from "../../../Assets/Images/BrandingPortfolio/branding-port-12.jpg";
import bpi3 from "../../../Assets/Images/BrandingPortfolio/branding-port-13.jpg";
import bpi4 from "../../../Assets/Images/BrandingPortfolio/branding-port-14.jpg";
import bpi5 from "../../../Assets/Images/BrandingPortfolio/branding-port-15.jpg";
import bpi6 from "../../../Assets/Images/BrandingPortfolio/branding-port-16.jpg";
import bpi7 from "../../../Assets/Images/BrandingPortfolio/branding-port-17.jpg";
import bpi8 from "../../../Assets/Images/BrandingPortfolio/branding-port-18.jpg";
import bpi9 from "../../../Assets/Images/BrandingPortfolio/branding-port-19.jpg";

import anpi1 from "../../../Assets/Images/AnimationPortfolio/Scene-01.gif";
import anpi2 from "../../../Assets/Images/AnimationPortfolio/Scene-02.gif";
import anpi3 from "../../../Assets/Images/AnimationPortfolio/scene-03.gif";
import anpi4 from "../../../Assets/Images/AnimationPortfolio/scene-04.gif";
import anpi5 from "../../../Assets/Images/AnimationPortfolio/scene-05.gif";
import anpi6 from "../../../Assets/Images/AnimationPortfolio/scene-06.gif";
import anpi7 from "../../../Assets/Images/AnimationPortfolio/scene-07.gif";
import anpi8 from "../../../Assets/Images/AnimationPortfolio/scene-08.gif";
import anpi9 from "../../../Assets/Images/AnimationPortfolio/scene-09.gif";
import anpi10 from "../../../Assets/Images/AnimationPortfolio/scene-10.gif";

import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import GetFreeProposal from "../../../ShareButton/GetFreeProposal";
const ProjectMain = () => {
  const ref = useRef(null);
  useAnimationFrame((t) => {
    const y = (1 + Math.sin(t / 500)) * -20;
    ref.current.style.transform = `translateY(${y}px)`;
  });

  useEffect(() => {
    const tabsNav = document.querySelectorAll(".project-tab-btns ul li");
    const tabsContent = document.querySelectorAll(
      ".project-tabs-cntnt .project-tab-cntnt"
    );

    tabsNav.forEach((items, ind) => {
      items.addEventListener("click", () => {
        tabsNav.forEach((remove) => {
          remove.classList.remove("active");
        });

        items.classList.add("active");

        tabsContent.forEach((content) => {
          content.classList.remove("active");
        });

        tabsContent[ind].classList.add("active");
      });
    });
  }, []);
  useEffect(() => {
    Fancybox.bind("[data-fancybox]", {
      infinite: true,
      transitionEffect: "fade",
      buttons: ["zoom", "slideShow", "fullScreen", "close"],
      autoFocus: false,
      backFocus: false,
      animationEffect: "fade",
      clickContent: function (current, event) {
        return current.type === "image" ? "zoom" : false;
      },
    });
  }, []);

  return (
    <div>
      <section className="inner-banner-sec">
        <div className="container">
          <div className="inner-banner-row">
            <div className="inner-banner-col1">
              <motion.h1
                initial={{ x: -400, opacity: "0" }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.6 }}
              >
                Explore Our Portfolio
              </motion.h1>
              <motion.p
                initial={{ x: -400, opacity: "0" }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.9 }}
              >
                With years-long industry expertise, Teqnite Internet Marketing
                Agency has spearheaded 1200+ client businesses worldwide. Get a
                glimpse of our success stories and case studies to witness the
                transformative results we've achieved for our esteemed
                clientele.
              </motion.p>
              <motion.p
                initial={{ x: -400, opacity: "0" }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.9 }}
              >
                Explore how businesses across diverse sectors have flourished
                with our tailored digital marketing strategies and innovative
                solutions.
              </motion.p>
              <motion.div
                initial={{ x: -400, opacity: "0" }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 1.3 }}
              >
                <GetFreeProposal />
              </motion.div>
            </div>
            <motion.div
              className="inner-banner-col2"
              drag
              dragConstraints={{ left: 10, top: 10, right: 10, bottom: 10 }}
              style={{ cursor: "pointer" }}
              ref={ref}
            >
              <img src={img} alt="" />
            </motion.div>
          </div>
        </div>
      </section>

      <section className="project-tabs-sec">
        <div className="container">
          <div className="project-sec-cntnt">
            <div className="project-tab-btns">
              <ul>
                <li className="active">
                  <div className="p-tab-ico">
                    <img src={imgtab1} alt="" />
                  </div>
                  <span>Website</span>
                </li>
                <li>
                  <div className="p-tab-ico">
                    <img src={imgtab2} alt="" />
                  </div>
                  <span>Logo Design</span>
                </li>
                <li>
                  <div className="p-tab-ico">
                    <img src={imgtab3} alt="" />
                  </div>
                  <span>Branding</span>
                </li>
                <li>
                  <div className="p-tab-ico">
                    <img src={imgtab4} alt="" />
                  </div>
                  <span>2D/3D Animation</span>
                </li>
              </ul>
            </div>
            <div className="project-tabs-cntnt">
              <div className="project-tab-cntnt active">
                <div className="tabs-sec-grid web-hov">
                  {/* <div className="t-grid-col">
                    <img src={wpi1} alt="" data-fancybox="project-1" />
                  </div>
                  <div className="t-grid-col">
                    <img src={wpi2} alt="" data-fancybox="project-1" />
                  </div>
                  <div className="t-grid-col">
                    <img src={wpi3} alt="" data-fancybox="project-1" />
                  </div>
                  <div className="t-grid-col">
                    <img src={wpi4} alt="" data-fancybox="project-1" />
                  </div>
                  <div className="t-grid-col">
                    <img src={wpi5} alt="" data-fancybox="project-1" />
                  </div>
                  <div className="t-grid-col">
                    <img src={wpi6} alt="" data-fancybox="project-1" />
                  </div>
                  <div className="t-grid-col">
                    <img src={wpi7} alt="" data-fancybox="project-1" />
                  </div>
                  <div className="t-grid-col">
                    <img src={wpi8} alt="" data-fancybox="project-1" />
                  </div>
                  <div className="t-grid-col">
                    <img src={wpi9} alt="" data-fancybox="project-1" />
                  </div>
                  <div className="t-grid-col">
                    <img src={wpi10} alt="" data-fancybox="project-1" />
                  </div>
                  <div className="t-grid-col">
                    <img src={wpi11} alt="" data-fancybox="project-1" />
                  </div>
                  <div className="t-grid-col">
                    <img src={wpi12} alt="" data-fancybox="project-1" />
                  </div>
                  <div className="t-grid-col">
                    <img src={wpi13} alt="" data-fancybox="project-1" />
                  </div>
                  <div className="t-grid-col">
                    <img src={wpi14} alt="" data-fancybox="project-1" />
                  </div>
                  <div className="t-grid-col">
                    <img src={wpi15} alt="" data-fancybox="project-1" />
                  </div>
                  <div className="t-grid-col">
                    <img src={wpi16} alt="" data-fancybox="project-1" />
  </div>*/}
                  <div className="t-grid-col">
                    <img src={wpi17} alt="" data-fancybox="project-1" />
                  </div>
                  <div className="t-grid-col">
                    <img src={wpi18} alt="" data-fancybox="project-1" />
                  </div>
                  <div className="t-grid-col">
                    <img src={wpi19} alt="" data-fancybox="project-1" />
                  </div>
                  <div className="t-grid-col">
                    <img src={wpi20} alt="" data-fancybox="project-1" />
                  </div>
                  <div className="t-grid-col">
                    <img src={wpi21} alt="" data-fancybox="project-1" />
                  </div>
                  <div className="t-grid-col">
                    <img src={wpi22} alt="" data-fancybox="project-1" />
                  </div>
                  <div className="t-grid-col">
                    <img src={wpi23} alt="" data-fancybox="project-1" />
                  </div>
                  <div className="t-grid-col">
                    <img src={wpi24} alt="" data-fancybox="project-1" />
                  </div>
                  <div className="t-grid-col">
                    <img src={wpi25} alt="" data-fancybox="project-1" />
                  </div>
                  <div className="t-grid-col">
                    <img src={wpi26} alt="" data-fancybox="project-1" />
                  </div>
                  <div className="t-grid-col">
                    <img src={wpi27} alt="" data-fancybox="project-1" />
                  </div>
                  <div className="t-grid-col">
                    <img src={wpi28} alt="" data-fancybox="project-1" />
                  </div>
                  <div className="t-grid-col">
                    <img src={wpi29} alt="" data-fancybox="project-1" />
                  </div>
                  <div className="t-grid-col">
                    <img src={wpi30} alt="" data-fancybox="project-1" />
                  </div>
                  <div className="t-grid-col">
                    <img src={wpi31} alt="" data-fancybox="project-1" />
                  </div>
                  <div className="t-grid-col">
                    <img src={wpi32} alt="" data-fancybox="project-1" />
                  </div>
                  <div className="t-grid-col">
                    <img src={wpi33} alt="" data-fancybox="project-1" />
                  </div>
                  <div className="t-grid-col">
                    <img src={wpi34} alt="" data-fancybox="project-1" />
                  </div>
                  <div className="t-grid-col">
                    <img src={wpi35} alt="" data-fancybox="project-1" />
                  </div>
                  <div className="t-grid-col">
                    <img src={wpi36} alt="" data-fancybox="project-1" />
                  </div>
                  <div className="t-grid-col">
                    <img src={wpi37} alt="" data-fancybox="project-1" />
                  </div>
                  <div className="t-grid-col">
                    <img src={wpi38} alt="" data-fancybox="project-1" />
                  </div>
                  <div className="t-grid-col">
                    <img src={wpi39} alt="" data-fancybox="project-1" />
                  </div>
                  <div className="t-grid-col">
                    <img src={wpi40} alt="" data-fancybox="project-1" />
                  </div>
                </div>
              </div>
              <div className="project-tab-cntnt ">
                <div className="tabs-sec-grid">
                  {/*<div className="t-grid-col">
                    <img src={gpi1} alt="" data-fancybox="project-2" />
                  </div>
                  <div className="t-grid-col">
                    <img src={gpi2} alt="" data-fancybox="project-2" />
                  </div>
                  <div className="t-grid-col">
                    <img src={gpi3} alt="" data-fancybox="project-2" />
                  </div>
                  <div className="t-grid-col">
                    <img src={gpi4} alt="" data-fancybox="project-2" />
                  </div>
                  <div className="t-grid-col">
                    <img src={gpi5} alt="" data-fancybox="project-2" />
                  </div>
                  <div className="t-grid-col">
                    <img src={gpi6} alt="" data-fancybox="project-2" />
                  </div>
                  <div className="t-grid-col">
                    <img src={gpi7} alt="" data-fancybox="project-2" />
                  </div>
                  <div className="t-grid-col">
                    <img src={gpi8} alt="" data-fancybox="project-2" />
                  </div>
                  <div className="t-grid-col">
                    <img src={gpi9} alt="" data-fancybox="project-2" />
                  </div>
                  <div className="t-grid-col">
                    <img src={gpi10} alt="" data-fancybox="project-2" />
                  </div>
                  <div className="t-grid-col">
                    <img src={gpi11} alt="" data-fancybox="project-2" />
                  </div>
                  <div className="t-grid-col">
                    <img src={gpi12} alt="" data-fancybox="project-2" />
                  </div>
                  <div className="t-grid-col">
                    <img src={gpi13} alt="" data-fancybox="project-2" />
                  </div>
                  <div className="t-grid-col">
                    <img src={gpi14} alt="" data-fancybox="project-2" />
                  </div>
                  <div className="t-grid-col">
                    <img src={gpi15} alt="" data-fancybox="project-2" />
                  </div>
                  <div className="t-grid-col">
                    <img src={gpi16} alt="" data-fancybox="project-2" />
  </div>*/}
                  <div className="t-grid-col">
                    <img src={gpi17} alt="" data-fancybox="project-2" />
                  </div>
                  <div className="t-grid-col">
                    <img src={gpi18} alt="" data-fancybox="project-2" />
                  </div>
                  <div className="t-grid-col">
                    <img src={gpi19} alt="" data-fancybox="project-2" />
                  </div>
                  <div className="t-grid-col">
                    <img src={gpi20} alt="" data-fancybox="project-2" />
                  </div>
                  <div className="t-grid-col">
                    <img src={gpi21} alt="" data-fancybox="project-2" />
                  </div>
                  <div className="t-grid-col">
                    <img src={gpi22} alt="" data-fancybox="project-2" />
                  </div>
                  <div className="t-grid-col">
                    <img src={gpi23} alt="" data-fancybox="project-2" />
                  </div>
                  <div className="t-grid-col">
                    <img src={gpi24} alt="" data-fancybox="project-2" />
                  </div>
                  <div className="t-grid-col">
                    <img src={gpi25} alt="" data-fancybox="project-2" />
                  </div>
                  <div className="t-grid-col">
                    <img src={gpi26} alt="" data-fancybox="project-2" />
                  </div>
                  <div className="t-grid-col">
                    <img src={gpi27} alt="" data-fancybox="project-2" />
                  </div>
                  <div className="t-grid-col">
                    <img src={gpi28} alt="" data-fancybox="project-2" />
                  </div>
                  <div className="t-grid-col">
                    <img src={gpi29} alt="" data-fancybox="project-2" />
                  </div>
                  <div className="t-grid-col">
                    <img src={gpi30} alt="" data-fancybox="project-2" />
                  </div>
                  <div className="t-grid-col">
                    <img src={gpi31} alt="" data-fancybox="project-2" />
                  </div>
                  <div className="t-grid-col">
                    <img src={gpi32} alt="" data-fancybox="project-2" />
                  </div>
                </div>
              </div>
              <div className="project-tab-cntnt ">
                <div className="tabs-sec-grid">
                  <div className="t-grid-col">
                    <img src={bpi1} alt="" data-fancybox="project-3" />
                  </div>
                  <div className="t-grid-col">
                    <img src={bpi2} alt="" data-fancybox="project-3" />
                  </div>
                  <div className="t-grid-col">
                    <img src={bpi3} alt="" data-fancybox="project-3" />
                  </div>
                  <div className="t-grid-col">
                    <img src={bpi4} alt="" data-fancybox="project-3" />
                  </div>
                  <div className="t-grid-col">
                    <img src={bpi5} alt="" data-fancybox="project-3" />
                  </div>
                  <div className="t-grid-col">
                    <img src={bpi6} alt="" data-fancybox="project-3" />
                  </div>
                  <div className="t-grid-col">
                    <img src={bpi7} alt="" data-fancybox="project-3" />
                  </div>
                  <div className="t-grid-col">
                    <img src={bpi8} alt="" data-fancybox="project-3" />
                  </div>
                  <div className="t-grid-col">
                    <img src={bpi9} alt="" data-fancybox="project-3" />
                  </div>
                  <div className="t-grid-col">
                    <img src={bpi2} alt="" data-fancybox="project-3" />
                  </div>
                  <div className="t-grid-col">
                    <img src={bpi7} alt="" data-fancybox="project-3" />
                  </div>
                  <div className="t-grid-col">
                    <img src={bpi1} alt="" data-fancybox="project-3" />
                  </div>
                </div>
              </div>
              <div className="project-tab-cntnt ">
                <div className="tabs-sec-grid">
                  <div className="t-grid-col">
                    <img src={anpi1} alt="" data-fancybox="project-4" />
                  </div>
                  <div className="t-grid-col">
                    <img src={anpi2} alt="" data-fancybox="project-4" />
                  </div>
                  <div className="t-grid-col">
                    <img src={anpi3} alt="" data-fancybox="project-4" />
                  </div>
                  <div className="t-grid-col">
                    <img src={anpi4} alt="" data-fancybox="project-4" />
                  </div>
                  <div className="t-grid-col">
                    <img src={anpi5} alt="" data-fancybox="project-4" />
                  </div>
                  <div className="t-grid-col">
                    <img src={anpi6} alt="" data-fancybox="project-4" />
                  </div>
                  <div className="t-grid-col">
                    <img src={anpi7} alt="" data-fancybox="project-4" />
                  </div>
                  <div className="t-grid-col">
                    <img src={anpi8} alt="" data-fancybox="project-4" />
                  </div>
                  <div className="t-grid-col">
                    <img src={anpi9} alt="" data-fancybox="project-4" />
                  </div>
                  <div className="t-grid-col">
                    <img src={anpi10} alt="" data-fancybox="project-4" />
                  </div>
                  <div className="t-grid-col">
                    <img src={anpi3} alt="" data-fancybox="project-4" />
                  </div>
                  <div className="t-grid-col">
                    <img src={anpi8} alt="" data-fancybox="project-4" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ProjectMain;
