import bpi1 from "../../../Assets/Images/BrandingPortfolio/branding-port-11.jpg";
import bpi2 from "../../../Assets/Images/BrandingPortfolio/branding-port-12.jpg";
import bpi3 from "../../../Assets/Images/BrandingPortfolio/branding-port-13.jpg";
import bpi4 from "../../../Assets/Images/BrandingPortfolio/branding-port-14.jpg";
import bpi5 from "../../../Assets/Images/BrandingPortfolio/branding-port-15.jpg";
import bpi6 from "../../../Assets/Images/BrandingPortfolio/branding-port-16.jpg";
import bpi7 from "../../../Assets/Images/BrandingPortfolio/branding-port-17.jpg";
import bpi8 from "../../../Assets/Images/BrandingPortfolio/branding-port-18.jpg";

const DataBranding = [
  {
    id: 1,
    img: bpi1,
  },
  {
    id: 2,
    img: bpi2,
  },
  {
    id: 3,
    img: bpi3,
  },
  {
    id: 4,
    img: bpi4,
  },
  {
    id: 5,
    img: bpi5,
  },
  {
    id: 6,
    img: bpi6,
  },
  {
    id: 7,
    img: bpi7,
  },
  {
    id: 8,
    img: bpi8,
  },
];
export default DataBranding;
