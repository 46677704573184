import img1 from "../Assets/Images/industry-1.svg";
import img2 from "../Assets/Images/industry-2.svg";
import img3 from "../Assets/Images/industry-3.svg";
import img4 from "../Assets/Images/industry-4.svg";
import img5 from "../Assets/Images/industry-5.svg";
import img6 from "../Assets/Images/industry-6.svg";
import img7 from "../Assets/Images/industry-7.svg";
import img8 from "../Assets/Images/industry-8.svg";
import img9 from "../Assets/Images/industry-9.svg";
import img10 from "../Assets/Images/industry-10.svg";
import img11 from "../Assets/Images/industry-11.svg";
import img12 from "../Assets/Images/industry-12.svg";
import img13 from "../Assets/Images/industry-13.svg";
import img14 from "../Assets/Images/industry-14.svg";
import img15 from "../Assets/Images/industry-15.svg";
import img16 from "../Assets/Images/industry-16.svg";

const IndustriesData = [
  {
    id: 1,
    Img: img1,
    title: "Real estate",
  },
  {
    id: 2,
    Img: img2,
    title: "Tour & Travels",
  },
  {
    id: 3,
    Img: img3,
    title: "Education",
  },
  {
    id: 4,
    Img: img4,
    title: "Transport",
  },
  {
    id: 5,
    Img: img5,
    title: "Event",
  },
  {
    id: 6,
    Img: img6,
    title: "eCommerce",
  },
  {
    id: 7,
    Img: img7,
    title: "Game",
  },
  {
    id: 8,
    Img: img8,
    title: "Healthcare",
  },

  {
    id: 9,
    Img: img9,
    title: "Finance",
  },
  {
    id: 10,
    Img: img10,
    title: "Restaurant",
  },
  {
    id: 11,
    Img: img11,
    title: "On-Demand",
  },
  {
    id: 12,
    Img: img12,
    title: "Grocery",
  },
  {
    id: 13,
    Img: img13,
    title: "Skincare",
  },
  {
    id: 14,
    Img: img14,
    title: "Fashion",
  },
  {
    id: 15,
    Img: img15,
    title: "Food And Nutrition",
  },
  {
    id: 16,
    Img: img16,
    title: "Lifestyle",
  },
];
export default IndustriesData;
