import React, { useEffect } from "react";
import img1 from "../../../Assets/Images/tgd-icon-1.svg";
import img2 from "../../../Assets/Images/tgd-icon-2.svg";
import img3 from "../../../Assets/Images/tgd-icon-3.svg";
import img4 from "../../../Assets/Images/tgd-icon-4.svg";
import img5 from "../../../Assets/Images/tgd-icon-5.svg";
import img6 from "../../../Assets/Images/tgd-icon-6.svg";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import { Fancybox } from "@fancyapps/ui";
import { Link } from "react-router-dom";
import DataAimation from "./Data/PortfolioData";
import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.min.css";
import GetFreeProposal from "../../../ShareButton/GetFreeProposal";

const TabsWhycho0se = () => {
  useEffect(() => {
    Fancybox.bind("[data-fancybox]", {
      infinite: true,
      transitionEffect: "fade",
      buttons: ["zoom", "slideShow", "fullScreen", "close"],
      autoFocus: false,
      backFocus: false,
      animationEffect: "fade",
      clickContent: function (current, event) {
        return current.type === "image" ? "zoom" : false;
      },
    });
  }, []);
  return (
    <div>
      <section className="benefits-sec PT5">
        <div className="container">
          <div className="benefits-sec-title">
            <ScrollAnimation animateIn="fadeIn">
              <h2>Why Choose Teqnite 2D/3D Video Animation Agency</h2>
              <p>
                Choose Teqnite for 2D/3D Animation Services and experience a
                seamless blend of creative brilliance and technical expertise.
                Our commitment to delivering excellent quality within stipulated
                timelines ensures your brand receives unique design solutions.
              </p>
            </ScrollAnimation>
          </div>
          <div className="benefits-blocks-row">
            <div className="bnf-cell">
              <ScrollAnimation animateIn="fadeIn">
                <div className="bnf-ico">
                  <img src={img1} alt="" />
                </div>
                <span>Reliable Service. In House Team</span>
              </ScrollAnimation>
            </div>
            <div className="bnf-cell">
              <ScrollAnimation animateIn="fadeIn">
                <div className="bnf-ico">
                  <img src={img2} alt="" />
                </div>
                <span>Trusted by People Like You</span>
              </ScrollAnimation>
            </div>
            <div className="bnf-cell">
              <ScrollAnimation animateIn="fadeIn">
                <div className="bnf-ico">
                  <img src={img3} alt="" />
                </div>
                <span>Complete Technical Competency</span>
              </ScrollAnimation>
            </div>
            <div className="bnf-cell">
              <ScrollAnimation animateIn="fadeIn">
                <div className="bnf-ico">
                  <img src={img4} alt="" />
                </div>
                <span>Friendly & Cordial in Nature</span>
              </ScrollAnimation>
            </div>
            <div className="bnf-cell">
              <ScrollAnimation animateIn="fadeIn">
                <div className="bnf-ico">
                  <img src={img5} alt="" />
                </div>
                <span>Excellent Quality Delivered on Time</span>
              </ScrollAnimation>
            </div>
            <div className="bnf-cell">
              <ScrollAnimation animateIn="fadeIn">
                <div className="bnf-ico">
                  <img src={img6} alt="" />
                </div>
                <span>Effective & Continuous Communication</span>
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </section>

      <section className="inner-potfolio-sec">
        <div className="container">
          <div className="inner-port-title">
            <ScrollAnimation animateIn="fadeIn">
              <h2>Teqnite 2D/3D Business Animation Videos Portfolio</h2>
              <p>
                Explore the Teqnite video animation production agency's
                portfolio to witness a visual journey of creativity and
                innovation. Our portfolio showcases a diverse series of design
                projects, each proof of our commitment to excellence and unique
                design solutions.
              </p>
            </ScrollAnimation>
          </div>
          <div className="tabs-sec-grid">
            {DataAimation.map((item, index) => (
              <div className="t-grid-col" key={index}>
                <ScrollAnimation animateIn="fadeIn">
                  <img src={item.img} alt="" data-fancybox="inner-gallery" />
                </ScrollAnimation>
              </div>
            ))}
          </div>
          <div className="inner-port-cta">
            <GetFreeProposal />
          </div>
        </div>
      </section>
    </div>
  );
};

export default TabsWhycho0se;
