import React from "react";
import { Link } from "react-router-dom";
import img1 from "../Assets/Images/model-ico1.svg";
import img2 from "../Assets/Images/model-ico2.svg";
import img3 from "../Assets/Images/model-ico3.svg";
import img4 from "../Assets/Images/model-ico4.svg";
import img5 from "../Assets/Images/model-ico5.svg";
import img6 from "../Assets/Images/model-ico6.svg";
import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.min.css";
import ShareButton from "../ShareButton/GetFreeProposal";

const Operations = () => {
  return (
    <div>
      <section className="models">
        <div className="container">
          <div className="models_clmn_hldr">
            <div className="model_cntnt_clmn">
              <ScrollAnimation animateIn="fadeIn">
                <h2>
                  A Decade of Innovation: Teqnite Digital Leads the Way to
                  Success
                </h2>
              </ScrollAnimation>
              <ScrollAnimation animateIn="fadeIn">
                <p>
                  Ready to crush your digital goals? Buckle up because Teqnite's
                  taking you on a ride! We're not just any digital marketing
                  agency - we have 10+ years of experience, 150+ talented squad,
                  and 1200+ successful projects under our belt (that's a 100%
                  win rate for those keeping score!).
                </p>
                <br />
                <p>
                  Forget "one size fits all" strategies. We've helped businesses
                  from over 120 countries across the globe achieve epic results
                  because we're obsessed with innovation and customization.
                  Let's make your brand legendary!
                </p>
              </ScrollAnimation>
              {/* <ScrollAnimation animateIn="fadeIn">
                <h4>Let's Start a New Project with Teqnite.</h4>
              </ScrollAnimation> */}
              <ScrollAnimation animateIn="fadeIn" style={{ marginTop: "30px" }}>
                <ShareButton />
              </ScrollAnimation>
            </div>
            <div className="model_box_clmn">
              <div className="model_box">
                <ScrollAnimation animateIn="fadeIn">
                  <div className="mb-ico">
                    <img src={img1} alt="" />
                  </div>
                  <div className="mb-info">
                    <span>10+</span>
                    <p>Years Experience</p>
                  </div>
                </ScrollAnimation>
              </div>
              <div className="model_box">
                <ScrollAnimation animateIn="fadeIn">
                  <div className="mb-ico">
                    <img src={img2} alt="" />
                  </div>
                  <div className="mb-info">
                    <span>150+</span>
                    <p>Talented Squad</p>
                  </div>
                </ScrollAnimation>
              </div>
              <div className="model_box">
                <ScrollAnimation animateIn="fadeIn">
                  <div className="mb-ico">
                    <img src={img3} alt="" />
                  </div>
                  <div className="mb-info">
                    <span>1200+</span>
                    <p>Project Developed</p>
                  </div>
                </ScrollAnimation>
              </div>
              <div className="model_box">
                <ScrollAnimation animateIn="fadeIn">
                  <div className="mb-ico">
                    <img src={img4} alt="" />
                  </div>
                  <div className="mb-info">
                    <span>100%</span>
                    <p>Projects Delivered</p>
                  </div>
                </ScrollAnimation>
              </div>
              <div className="model_box">
                <ScrollAnimation animateIn="fadeIn">
                  <div className="mb-ico">
                    <img src={img5} alt="" />
                  </div>
                  <div className="mb-info">
                    <span>120+</span>
                    <p>Countries Served</p>
                  </div>
                </ScrollAnimation>
              </div>
              <div className="model_box">
                <ScrollAnimation animateIn="fadeIn">
                  <div className="mb-ico">
                    <img src={img6} alt="" />
                  </div>
                  <div className="mb-info">
                    <span>100%</span>
                    <p>Client Satisfaction</p>
                  </div>
                </ScrollAnimation>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Operations;
