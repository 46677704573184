import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import Index from "../Components/Home/Index";
import Header from "../Components/Header/Header";
import Footer from "../Components/Footer/Footer";
import "../Components/Assets/Cssfile/index.css";
import "../Components/Assets/Cssfile/responsive.css";
import Design from "../Components/GraphicTemplate/DesignPage/Designpage.jsx";
import Animation from "../Components/Pages/2danimationPage/AnimationMain/Index.jsx";
import AppDevelopment from "../Components/Pages/AppDevelopment/AppDevelopmentMain/Index.jsx";
import WebDesign from "../Components/Pages/WebDesign/WebsiteDesignmain/index.jsx";
import Digital from "../Components/Pages/DigitalMarketing/DigitalMarketingMain/Index.jsx";
import About from "../Components/Pages/AboutPage/AboutIndex/Index.jsx";
// import Blog from "../Components/Pages/BlogPage/BlogIndex/Index.jsx";
// <Route path="blog" element={<Blog />}/>;
import OurProjects from "../Components/Pages/ProjectPage/ProjectIndex/Index.jsx";
import BlogInner from "../Components/Pages/BlogsInner/BlogInnerIndex/Index.jsx";
import Testimonials from "../Components/Pages/TestimonialsPage/TestimonialsIndex/Index.jsx";
import Branding from "../Components/Pages/BrandingPage/BrandingIndex/Index.jsx";
import NotFoundComponent from "../Components/NotFoundComponent.jsx";
import Contact from "../Components/Pages/ContactUsPage/ContactIndex/Index.jsx";
import Privacy from "../Components/Pages/Privacy/PrivacyPolicy.jsx";
import Gallary from "../Components/Pages/Gallary/BannerGallry/GallaryIndex.jsx";
import { ToastContainer } from "react-toastify";
// import PreLoader from "../Components/PreLoaderPage/PreLoader.jsx";

const Navigations = () => {
  // const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setLoading(false);
  //   }, 3000);

  //   return () => clearTimeout(timer);
  // }, []);

  // if (loading) {
  //   return <PreLoader />;
  // }
  return (
    <div>
      <Header />
      <Routes>
        <Route path="/" element={<Index />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/graphic-design" element={<Design />} />
        <Route path="/animation" element={<Animation />} />
        <Route path="/app-development" element={<AppDevelopment />} />
        <Route path="/web-development" element={<WebDesign />} />
        <Route path="/digital-marketing" element={<Digital />} />
        <Route path="/branding" element={<Branding />} />
        <Route path="/our-projects" element={<OurProjects />} />
        <Route path="/testimonials" element={<Testimonials />} />
        <Route path="/contact-us" element={<Contact />} />
        <Route path="/blog-inner/:id" element={<BlogInner />} />
        <Route path="/privacy-policy" element={<Privacy />} />
        <Route path="/our-gallary" element={<Gallary />} />
        <Route path="*" element={<NotFoundComponent />} />
      </Routes>
      <ToastContainer />
      <Footer />
    </div>
  );
};

export default Navigations;
