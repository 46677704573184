import React from "react";
import AppPage from "../AppHeader/AppPage";
// import IndexTabs from "../../../GraphicTemplate/DesignTabs/IndexTabs";
// import WhyChooseTeqnite from "../../../GraphicTemplate/WhyChooseTeqnite/WhyChooseTeqnite";
import GrowYourBusiness from "../../../Growbussnies/GrowYourBusiness";
import Testimonial from "../../../Testimonial/Testimonial";
import { Helmet } from "react-helmet";

const Index = () => {
  return (
    <div>
      <Helmet>
        <title>App Development Services: Mobile Apps, Web Apps & More!</title>
        <meta
          name="description"
          content="Elevate your business with our app development services. From mobile apps to web apps, we bring your ideas to life with cutting-edge technology."
        />
        <meta
          name="keywords"
          content="custom app development company, mobile app development services, best mobile app development services, mobile application development services, top mobile app developers, business mobile app development"
        />
      </Helmet>
      <AppPage />
      <GrowYourBusiness />
      <Testimonial />
    </div>
  );
};

export default Index;
