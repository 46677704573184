import React from "react";
import { Link } from "react-router-dom";
import img1 from "../Assets/Images/rev-author-img.png";
import img2 from "../Assets/Images/rev-stars-img.png";
import img3 from "../Assets/Images/google-logo.png";
import img4 from "../Assets/Images/clutch-logo.png";
import img5 from "../Assets/Images/upcity-logo.png";
import imgBanner from "../Assets/Images/Homebnner.png";
import ShareButton from "../ShareButton/GetFreeProposal";
// import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { MovingComponent } from "react-moving-text";
// import { Autoplay } from "swiper/modules";
// import HomePortfolio from "./Data/HomePortfolio";
const Banner = () => {
  return (
    <div>
      <section className="banner">
        <div className="container">
          <div className="banner_cntnt_hldr">
            <div className="banner_left_clmn">
              <span className="sub_title">
                <MovingComponent
                  type="fadeIn"
                  duration="1500ms"
                  delay="index * 100ms"
                  direction="alternate"
                  timing="linear"
                  iteration="1"
                  fillMode="both"
                >
                  Digital Marketing Agency
                </MovingComponent>
              </span>
              <h1>
                <MovingComponent
                  type="fadeIn"
                  duration="1500ms"
                  delay="index * 100ms"
                  direction="alternate"
                  timing="linear"
                  iteration="1"
                  fillMode="both"
                >
                  Teqnite Digital: Supercharge Your Business
                </MovingComponent>
              </h1>
              <p>
                <MovingComponent
                  type="fadeIn"
                  duration="1500ms"
                  delay="index * 100ms"
                  direction="alternate"
                  timing="linear"
                  iteration="1"
                  fillMode="both"
                >
                  Empower your presence online with top-notch digital marketing
                  services. Reach a wider audience and drive engagement to
                  elevate your brand. Working with us, you get.
                </MovingComponent>
              </p>
              <div className="banner_cta_row">
                <MovingComponent
                  type="fadeIn"
                  duration="1500ms"
                  delay="index * 100ms"
                  direction="alternate"
                  timing="linear"
                  iteration="1"
                  fillMode="both"
                >
                  <button>
                    <ShareButton />
                  </button>
                </MovingComponent>

                <MovingComponent
                  type="fadeIn"
                  duration="1500ms"
                  delay="index * 100ms"
                  direction="alternate"
                  timing="linear"
                  iteration="1"
                  fillMode="both"
                >
                  <div className="banner_rev_row">
                    <img src={img1} alt="" />
                    <div className="banner_rev_right">
                      <img src={img2} alt="" />
                      <span>Based on 500+ Reviews</span>
                    </div>
                  </div>
                </MovingComponent>
              </div>
            </div>
            <MovingComponent
              type="fadeIn"
              duration="1500ms"
              delay="index * 100ms"
              direction="alternate"
              timing="linear"
              iteration="1"
              fillMode="both"
            >
              <div className="banner_right_clmn">
                <div className="web-hov2">
                  <img src={imgBanner} alt="" />
                </div>

                {/* <Swiper
                  spaceBetween={30}
                  slidesPerView={1}
                  centeredSlides={true}
                  loop={true}
                  speed={500}
                  autoplay={{
                    delay: 4000,
                    disableOnInteraction: false,
                  }}
                  breakpoints={{
                    575: {
                      slidesPerView: 1,
                      spaceBetween: 15,
                    },
                    760: {
                      slidesPerView: 1,
                      spaceBetween: 15,
                    },
                    1024: {
                      slidesPerView: 1,
                      spaceBetween: 15,
                    },
                    1200: {
                      slidesPerView: 1,
                      spaceBetween: 30,
                    },
                  }}
                  modules={[Autoplay]}
                  className="mySwiper banner_slider"
                >
                  {HomePortfolio.map((item, index) => (
                    <SwiperSlide style={{ cursor: "pointer" }} key={index}>
                      <div className="swiper-slide web-hov2">
                        <img src={item.ImgPortfolio} alt="" />
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper> */}
              </div>
            </MovingComponent>
          </div>
          {/* <div className="banner_img_row">
            <div className="banner_img_hldr">
              <img src={img3} alt="" />
            </div>
            <div className="banner_img_hldr">
              <img src={img4} alt="" />
            </div>
            <div className="banner_img_hldr">
              <img src={img5} alt="" />
            </div>
          </div> */}
        </div>
      </section>
    </div>
  );
};

export default Banner;
