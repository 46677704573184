import React from "react";
import Main from "../TestimonialsMain/Main";
import { Helmet } from "react-helmet";

const Index = () => {
  return (
    <div>
      <Helmet>
        <title>Testimonials | Teqnite®</title>
        <meta
          name="description"
          content="Read what our clients say about Teqnite. Explore testimonials highlighting our commitment to excellence and the satisfaction of our clients."
        />
        <meta
          name="keywords"
          content="Digital marketing agency, Digital marketing agency, Digital marketing solutions"
        />
      </Helmet>
      <Main />
    </div>
  );
};

export default Index;
