import React, { useEffect, useRef } from "react";
import img1 from "../../../Assets/Images/testimonial-bnr-min.png";
import { Link } from "react-router-dom";
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import { motion, useAnimationFrame } from "framer-motion";
import DataTestimonialOne from "../../../Testimonial/Data/TestimonialOne";
import TestimonialDataTwo from "../../../Testimonial/Data/TestimonialTwo";
import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.min.css";
const Main = () => {
  const ref = useRef(null);
  useAnimationFrame((t) => {
    const y = (1 + Math.sin(t / 500)) * -20;
    ref.current.style.transform = `translateY(${y}px)`;
  });
  useEffect(() => {
    Fancybox.bind("[data-fancybox]", {
      infinite: true,
      transitionEffect: "fade",
      buttons: ["zoom", "slideShow", "fullScreen", "close"],
      autoFocus: false,
      backFocus: false,
      animationEffect: "fade",
      clickContent: function (current, event) {
        return current.type === "image" ? "zoom" : false;
      },
    });
  }, []);
  return (
    <div>
      <section className="inner-banner-sec">
        <div className="container">
          <div className="inner-banner-row">
            <div className="inner-banner-col1">
              <motion.h1
                initial={{ x: -400, opacity: "0" }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.6 }}
              >
                Top-Tier Digital Marketing Solutions: Our Testimonials
              </motion.h1>
              <motion.p
                initial={{ x: -400, opacity: "0" }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.9 }}
              >
                Want to see how real people like you are achieving amazing
                things with Teqnite Digital Marketing Agency? Check out these
                awesome testimonials from our clients! They're all about how
                Teqnite helped them reach their goals and get results, so you
                know it's the real deal. See for yourself how Teqnite can make a
                difference for you too!
              </motion.p>
              {/* <motion.p
                initial={{ x: -400, opacity: "0" }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.9 }}
              >
                Explore testimonials that reflect the transformative impact of
                Teqnite's services, where excellence meets client satisfaction.
              </motion.p> */}
              <motion.div
                initial={{ x: -400, opacity: "0" }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 1.3 }}
              >
                <Link
                  to="https://calendly.com/awais-xkw/website-design-development-consultation-with-client?month=2023-12"
                  target="__blank"
                >
                  Get Free Proposal
                </Link>
              </motion.div>
            </div>
            <motion.div
              className="inner-banner-col2"
              drag
              dragConstraints={{ left: 10, top: 10, right: 10, bottom: 10 }}
              style={{ cursor: "pointer" }}
              ref={ref}
            >
              <img src={img1} alt="" />
            </motion.div>
          </div>
        </div>
      </section>

      <section className="reviews-sec reviews-no-slid">
        <div className="container">
          <div className="reviews-sec-title">
            <h2>Over 1200+ Satisfied Clients and Growing</h2>
          </div>
          <div className="reviews-sec-row">
            <div className="rev-video-col">
              {DataTestimonialOne.map((item, index) => (
                <div className="vid-box" key={index}>
                  <ScrollAnimation animateIn="fadeIn">
                    <Link
                      to="javascript:;"
                      // data-fancybox="vid-revs"
                      // data-src={item.Video}
                    >
                      <img
                        src={item.img}
                        alt=""
                        data-fancybox="inner-gallery"
                      />
                    </Link>
                  </ScrollAnimation>
                </div>
              ))}
            </div>
            <div className="rev-testimonial-col">
              {TestimonialDataTwo.map((item, index) => (
                <div className="tst-box" key={index}>
                  <ScrollAnimation animateIn="fadeIn">
                    <div className="tst-cntnt">
                      <p>{item.Description}</p>
                    </div>
                    <div className="tst-info">
                      <div className="user-image">
                        <img src={item.Img} alt="" />
                      </div>
                      <div className="user-data">
                        <span>{item.Title}</span>
                        <p>{item.Designation}</p>
                      </div>
                    </div>
                  </ScrollAnimation>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Main;
