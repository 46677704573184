import React from "react";
import FootLogo from "../Assets/Images/logo-light.svg";
import { Link } from "react-router-dom";

const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div>
      <footer className="site-footer">
        <div className="container">
          <div className="f-top-row">
            <div className="foot-col col1">
              <div className="foot-logo">
                <Link to="/" onClick={scrollToTop}>
                  <img src={FootLogo} alt="" />
                </Link>
              </div>
              <p>
                Teqnite's been around for over a decade – to be your machete
                through the online wilderness! Think cutting-edge marketing
                strategies mixed with a sprinkle of creative genius. That's our
                recipe for digital success, and we're serving it hot to clients
                like you.
              </p>
            </div>
            <div className="foot-col col2">
              <h3>Menu</h3>
              <ul>
                <li>
                  <Link to="/" onClick={scrollToTop}>
                    Home
                  </Link>
                </li>
                <li>
                  <Link to="about-us" onClick={scrollToTop}>
                    About Us
                  </Link>
                </li>
                <li>
                  <Link to="graphic-design" onClick={scrollToTop}>
                    Graphic Design
                  </Link>
                </li>
                <li>
                  <Link to="web-development" onClick={scrollToTop}>
                    Web Development
                  </Link>
                </li>
                <li>
                  <Link to="app-development" onClick={scrollToTop}>
                    App Development
                  </Link>
                </li>
                <li>
                  <Link to="our-projects" onClick={scrollToTop}>
                    Our Projects
                  </Link>
                </li>
                <li>
                  <Link to="our-gallary" onClick={scrollToTop}>
                    Our Gallary
                  </Link>
                </li>
                <li>
                  <Link to="contact-us" onClick={scrollToTop}>
                    Contact Us
                  </Link>
                </li>
              </ul>
            </div>
            <div className="foot-col col3">
              <h3>Follow Us Here</h3>
              <ul>
                <li>
                  <Link to="https://www.instagram.com/teqnite/" target="_blank">
                    Instagram
                  </Link>
                </li>

                <li>
                  <Link to="https://www.facebook.com/teqnite" target="_blank">
                    Facebook
                  </Link>
                </li>
                <li>
                  <Link
                    to="https://www.linkedin.com/company/teqnite/"
                    target="_blank"
                  >
                    Linkedin
                  </Link>
                </li>
              </ul>
            </div>
            <div className="foot-col col4">
              <h3>Contact Us</h3>
              <span>
                Call:
                <Link to="tel:+16197988070">+1 619 798 8070</Link>
                {/* <Link to="tel:+442039620988">+442039620988</Link> */}
              </span>
              <span>
                Email:
                <Link to="mailto:sales@teqnite.com" target="_blank">
                  sales@teqnite.com
                </Link>
              </span>
              <span>
                Location:
                <Link
                  to="https://maps.app.goo.gl/vBqE2MbzXiRaYBmj8"
                  target="__blank"
                >
                  2803 Philadelphia Pike STE B 479 Claymont DE 19703
                </Link>
                {/* <Link
                  to="https://maps.app.goo.gl/uoQ4447FfLEk1A4XA"
                  target="__blank"
                >
                  AA House, 230-27 Old Gloucester Street, London
                </Link> */}
              </span>
            </div>
          </div>
          <div className="f-bottom-row">
            <div className="crc-1">
              <p>© 2023 Teqnite®. All rights reserved.</p>
            </div>
            <div className="crc-2">
              <ul>
                <li>
                  <Link to="/privacy-policy" onClick={scrollToTop}>
                    Privacy & Policy
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
