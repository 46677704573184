import React from "react";
import { Link } from "react-router-dom";
import ServicesData from "../Data/ServcieData";
import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.min.css";
const Services = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div>
      <div className="services-sec" id="services">
        <div className="container">
          <div className="svcs-title">
            <h2>Discover Our Services</h2>
          </div>
          <div className="svcs-grid">
            {ServicesData.map((item, index) => (
              <ScrollAnimation animateIn="fadeIn" key={index}>
                <div className="svcs-block">
                  <Link to={item.path} onClick={scrollToTop}>
                    <div className="svcs-name">
                      <h3>{item.title}</h3>
                    </div>
                    <div className="svcs-technologies">
                      {item.technologie &&
                      item.technologie.title1 &&
                      item.technologie.title2 &&
                      item.technologie.title3 &&
                      item.technologie.title4 ? (
                        <ul>
                          <li>{item.technologie.title1}</li>
                          <li>{item.technologie.title2}</li>
                          <li>{item.technologie.title3}</li>
                          <li>{item.technologie.title4}</li>
                        </ul>
                      ) : (
                        <ul>
                          <li>{item.technologie.title1}</li>
                          <li>{item.technologie.title2}</li>
                          <li>{item.technologie.title3}</li>
                          <li style={{ display: "none" }}>
                            {item.technologie.title4}
                          </li>
                        </ul>
                      )}

                      {item.techimg &&
                      item.techimg.tech1 &&
                      item.techimg.tech2 &&
                      item.techimg.tech3 &&
                      item.techimg.tech4 ? (
                        <ul className="tech-icos">
                          <li>
                            <img src={item.techimg.tech1} alt="" />
                          </li>
                          <li>
                            <img src={item.techimg.tech2} alt="" />
                          </li>
                          <li>
                            <img src={item.techimg.tech3} alt="" />
                          </li>
                          <li>
                            <img src={item.techimg.tech4} alt="" />
                          </li>
                        </ul>
                      ) : (
                        <ul className="tech-icos">
                          <li>
                            <img src={item.techimg.tech1} alt="" />
                          </li>
                          <li>
                            <img src={item.techimg.tech2} alt="" />
                          </li>
                          <li>
                            <img src={item.techimg.tech3} alt="" />
                          </li>
                          <li style={{ display: "none" }}>
                            <img src={item.techimg.tech4} alt="" />
                          </li>
                        </ul>
                      )}
                    </div>
                    <div className="svcs-cntnt">
                      <p>{item.desc}</p>
                      <Link to={item.path} onClick={scrollToTop}>
                        <span>Read More</span>
                      </Link>
                    </div>
                  </Link>
                </div>
              </ScrollAnimation>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
