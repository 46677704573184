import React from "react";
import Headeranimation from "../Headeranimation/Header";
// import IndexTabs from "../../../GraphicTemplate/DesignTabs/IndexTabs";
// import WhyChooseTeqnite from "../../../GraphicTemplate/WhyChooseTeqnite/WhyChooseTeqnite";
import GrowYourBusiness from "../../../Growbussnies/GrowYourBusiness";
import Testimonial from "../../../Testimonial/Testimonial";
import { Helmet } from "react-helmet";
const Index = () => {
  return (
    <div>
      <Helmet>
        <title>2D & 3D Animation Services | Teqnite®</title>
        <meta
          name="description"
          content="Immerse your audience with our 2D & 3D animation services. From concept to execution, we bring your ideas to life with creativity and precision."
        />
        <meta
          name="keywords"
          content="video animation services, video animation agency, video animation production agency, business animation videos, animation services, product animation"
        />
      </Helmet>
      <Headeranimation />
      <GrowYourBusiness />
      <Testimonial />
    </div>
  );
};

export default Index;
