import wpi1 from "../../../../Assets/Images/WebPortfolio/web-port-17.jpg";
import wpi2 from "../../../../Assets/Images/WebPortfolio/web-port-18.jpg";
import wpi3 from "../../../../Assets/Images/WebPortfolio/web-port-19.jpg";
import wpi4 from "../../../../Assets/Images/WebPortfolio/web-port-20.jpg";
import wpi5 from "../../../../Assets/Images/WebPortfolio/web-port-21.jpg";
import wpi6 from "../../../../Assets/Images/WebPortfolio/web-port-22.jpg";
import wpi7 from "../../../../Assets/Images/WebPortfolio/web-port-23.jpg";
import wpi8 from "../../../../Assets/Images/WebPortfolio/web-port-24.jpg";

const DataWeb = [
  {
    id: 1,
    img: wpi1,
  },
  {
    id: 2,
    img: wpi2,
  },
  {
    id: 3,
    img: wpi3,
  },
  {
    id: 4,
    img: wpi4,
  },
  {
    id: 5,
    img: wpi5,
  },
  {
    id: 6,
    img: wpi6,
  },
  {
    id: 7,
    img: wpi7,
  },
  {
    id: 8,
    img: wpi8,
  },
];
export default DataWeb;
