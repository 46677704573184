import React, { useRef } from "react";
import Happyclient from "../../../GraphicTemplate/HappyClient/Happyclient";
import img1 from "../../../Assets/Images/app-dev-bnr-col.png";
import img2 from "../../../Assets/Images/app-mask.jpg";
import img3 from "../../../Assets/Images/appInterfaces-1.svg";
import img4 from "../../../Assets/Images/appInterfaces-2.svg";
import img5 from "../../../Assets/Images/appInterfaces-3.svg";
import { Link } from "react-router-dom";
import Technologies from "../Technologies/Technologies";
import { motion, useAnimationFrame } from "framer-motion";
import TabsApp from "../TabsApp/TabsApp";
import PWC from "../PortfolioWhyChoose/PWC";

import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.min.css";
import ScheduleFreeConsult from "../../../ShareButton/ScheduleFreeConsult";

const AppPage = () => {
  const ref = useRef(null);
  useAnimationFrame((t) => {
    const y = (1 + Math.sin(t / 500)) * -20;
    ref.current.style.transform = `translateY(${y}px)`;
  });
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div>
      <section class="inner-banner-sec">
        <div class="container">
          <div class="inner-banner-row">
            <div class="inner-banner-col1">
              <motion.h1
                initial={{ x: -400, opacity: "0" }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.6 }}
              >
                Ground-Breaking Mobile App Development Services
              </motion.h1>
              <motion.p
                initial={{ x: -400, opacity: "0" }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.9 }}
              >
                Tired of the same old app experience? It's time to innovate!
                Teqnite Mobile App Development company crafts captivating,
                cutting-edge apps that wow users and propel your business
                forward. Supercharge your brand with a powerful, feature-rich
                app that exceeds expectations.
              </motion.p>
              {/* <motion.p
                initial={{ x: -400, opacity: "0" }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.9 }}
              >
                Choose Teqnite for groundbreaking development that exceeds
                expectations.
              </motion.p> */}
              <motion.div
                initial={{ x: -400, opacity: "0" }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 1.3 }}
              >
                <ScheduleFreeConsult />
              </motion.div>
            </div>
            <motion.div
              class="inner-banner-col2"
              drag
              dragConstraints={{ left: 10, top: 10, right: 10, bottom: 10 }}
              style={{ cursor: "pointer" }}
              ref={ref}
            >
              <img src={img1} alt="" />
            </motion.div>
          </div>
        </div>
      </section>

      <Happyclient />

      <section class="mask-details-sec">
        <div class="container">
          <div class="mast-details-row">
            <div class="mast-details-col1">
              <ScrollAnimation animateIn="fadeIn">
                <img src={img2} alt="" />
              </ScrollAnimation>
            </div>
            <div class="mast-details-col2">
              <ScrollAnimation animateIn="fadeIn">
                <h2>Best Mobile App Development Services</h2>
              </ScrollAnimation>
              <ScrollAnimation animateIn="fadeIn">
                <p>
                  Teqnite provides top-notch app development services,
                  specializing in business mobile app development and enterprise
                  solutions. Our process emphasizes user-centric design,
                  cutting-edge tech, and exceeding industry standards, ensuring
                  a seamless journey from idea to launch. Choose Teqnite for
                  standout apps that drive success in the ever-changing app
                  world and engage users like never before with intuitive,
                  user-friendly design. Our top mobile app developers bring
                  innovation, passion, and expertise to every project. We don't
                  just build apps; we make success stories.
                </p>
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </section>

      <Technologies />

      <section class="offers-sec">
        <div class="container">
          <div class="offers-sec-title">
            <ScrollAnimation animateIn="fadeIn">
              <h2>Functional App Interfaces</h2>
              <p>
                We are a custom mobile app development company that follows a
                proven process and consistently delivers winning designs,
                boosting conversions and elevating brand recognition.
              </p>
            </ScrollAnimation>
          </div>
          <div class="offers-sec-grid">
            <div class="s-offer-cell">
              <ScrollAnimation animateIn="fadeIn">
                <div class="s-offer-name">
                  <div class="s-offer-ico">
                    <img src={img3} alt="" />
                  </div>
                  <h3>Web Application Development</h3>
                </div>
                <p>
                  Robust web applications with functional interfaces and their
                  seamless integration into a solid digital strategy, this is
                  Teqnite Digital— a creative hub for all your digital marketing
                  needs.
                </p>
              </ScrollAnimation>
            </div>
            <div class="s-offer-cell">
              <ScrollAnimation animateIn="fadeIn">
                <div class="s-offer-name">
                  <div class="s-offer-ico">
                    <img src={img4} alt="" />
                  </div>
                  <h3>Mobile Application Development</h3>
                </div>
                <p>
                  Upgrade your brand's mobile presence with Teqnite's mobile App
                  Development services. Benefit from our expert team's
                  experience and knowledge. Get a beautiful, functional app that
                  users adore. Enjoy a smooth and transparent development
                  process.
                </p>
              </ScrollAnimation>
            </div>
            <div class="s-offer-cell">
              <ScrollAnimation animateIn="fadeIn">
                <div class="s-offer-name">
                  <div class="s-offer-ico">
                    <img src={img5} alt="" />
                  </div>
                  <h3>Progressive Web Apps</h3>
                </div>
                <p>
                  Level Up Your Web with Next-Gen PWAs. Craft lightning-fast,
                  ultra-reliable web experiences that captivate users. Our
                  cutting-edge Progressive Web App development prioritizes
                  intuitive interfaces, boosting user engagement and
                  satisfaction. Embrace the future, embrace Teqnite's PWAs.
                </p>
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </section>

      <section className="hire-me-sec">
        <div className="container">
          <ScrollAnimation animateIn="fadeIn">
            <div className="hire-me-block">
              <div className="hire-me-cntnt">
                <div className="hm-text">
                  <h2>Engage with Seamless App Development!</h2>
                </div>
                <div className="hm-cta">
                  <Link to="/contact-us" onClick={scrollToTop}>
                    Get In Touch
                  </Link>
                </div>
              </div>
            </div>
          </ScrollAnimation>
        </div>
      </section>

      <TabsApp />
      <PWC />
    </div>
  );
};

export default AppPage;
