import img1 from "../../../Assets/Images/bnf-1.svg";
import img2 from "../../../Assets/Images/bnf-2.svg";
import img3 from "../../../Assets/Images/bnf-3.svg";
import img4 from "../../../Assets/Images/bnf-4.svg";
const AboutData = [
  {
    id: 1,
    Img: img1,
    title: "Focused Approach for Business",
    Description:
      "Choose our digital marketing services for the laser-focused business approach to help you succeed. Grow your brand beyond expectations with our unwavering commitment to excellence. Our track record shows proven results, time and again. Over the years, we've helped countless clients achieve their digital goals with our digital marketing strategies and campaigns, from startups to multinational corporations.",
  },
  {
    id: 2,
    Img: img2,
    title: "Expertise That Sets Us Apart",
    Description:
      "Our seasoned professionals have a deep knowledge of the digital landscape. From SEO wizards to social media maestros, we have what it takes to elevate your brand and drive meaningful growth.From SEO wizards to social media maestros, we have the expertise to lift up your brand and drive meaningful growth.",
  },
  {
    id: 3,
    Img: img3,
    title: "One-Window Solution",
    Description:
      "Simplify your journey as we seamlessly integrate diverse services under one roof. Choose Teqnite for a streamlined experience, where all your digital needs find expertly managed solutions. Whether you're looking to increase your brand awareness, drive traffic, or boost conversions, we can transform your digital presence into a powerhouse of success!",
  },
  {
    id: 4,
    Img: img4,
    title: "Optimal Source Code Delivery",
    Description:
      "Choose Teqnite's transparency and reliability with our source code delivery. We believe in empowering our clients with full control. Rest easy knowing you'll receive not just exceptional solutions but the SOURCE CODE, ensuring transparency, flexibility, and the confidence to shape your digital future with ease.",
  },
];
export default AboutData;
