import React, { useEffect } from "react";
import img1 from "../../../Assets/Images/tgd-icon-1.svg";
import img2 from "../../../Assets/Images/tgd-icon-2.svg";
import img3 from "../../../Assets/Images/tgd-icon-3.svg";
import img4 from "../../../Assets/Images/tgd-icon-4.svg";
import img5 from "../../../Assets/Images/tgd-icon-5.svg";
import img6 from "../../../Assets/Images/tgd-icon-6.svg";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import { Fancybox } from "@fancyapps/ui";
import { Link } from "react-router-dom";

import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.min.css";

const PWC = () => {
  useEffect(() => {
    Fancybox.bind("[data-fancybox]", {
      infinite: true,
      transitionEffect: "fade",
      buttons: ["zoom", "slideShow", "fullScreen", "close"],
      autoFocus: false,
      backFocus: false,
      animationEffect: "fade",
      clickContent: function (current, event) {
        return current.type === "image" ? "zoom" : false;
      },
    });
  }, []);
  return (
    <div>
      <section className="benefits-sec app_Bene_Pad">
        <div className="container">
          <div className="benefits-sec-title">
            <ScrollAnimation animateIn="fadeIn">
              <h2>Why Choose Teqnite App Development Service?</h2>
              <p>
                Reflect your company's true self through tone of voice,
                copywriting, and storytelling with us.
              </p>
            </ScrollAnimation>
          </div>
          <div className="benefits-blocks-row">
            <div className="bnf-cell">
              <ScrollAnimation animateIn="fadeIn">
                <div className="bnf-ico">
                  <img src={img1} alt="" />
                </div>
                <span>Reliable Service. In House Team</span>
              </ScrollAnimation>
            </div>
            <div className="bnf-cell">
              <ScrollAnimation animateIn="fadeIn">
                <div className="bnf-ico">
                  <img src={img2} alt="" />
                </div>
                <span>Trusted by People Like You</span>
              </ScrollAnimation>
            </div>
            <div className="bnf-cell">
              <ScrollAnimation animateIn="fadeIn">
                <div className="bnf-ico">
                  <img src={img3} alt="" />
                </div>
                <span>Complete Technical Competency</span>
              </ScrollAnimation>
            </div>
            <div className="bnf-cell">
              <ScrollAnimation animateIn="fadeIn">
                <div className="bnf-ico">
                  <img src={img4} alt="" />
                </div>
                <span>Friendly & Cordial in Nature</span>
              </ScrollAnimation>
            </div>
            <div className="bnf-cell">
              <ScrollAnimation animateIn="fadeIn">
                <div className="bnf-ico">
                  <img src={img5} alt="" />
                </div>
                <span>Excellent Quality Delivered on Time</span>
              </ScrollAnimation>
            </div>
            <div className="bnf-cell">
              <ScrollAnimation animateIn="fadeIn">
                <div className="bnf-ico">
                  <img src={img6} alt="" />
                </div>
                <span>Effective & Continuous Communication</span>
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </section>

      {/*<section className="inner-potfolio-sec">
        <div className="container">
          <div className="inner-port-title">
            <h2>Our App Development Portfolio</h2>
            <p>
              We help your company to reflect its true self through tone of
              voice, copywriting and storytelling.
            </p>
          </div>
          <div className="tabs-sec-grid">
            <div className="t-grid-col">
              <img src={img7} alt="" data-fancybox="inner-gallery" />
            </div>
            <div className="t-grid-col">
              <img src={img7} alt="" data-fancybox="inner-gallery" />
            </div>
            <div className="t-grid-col">
              <img src={img7} alt="" data-fancybox="inner-gallery" />
            </div>
            <div className="t-grid-col">
              <img src={img7} alt="" data-fancybox="inner-gallery" />
            </div>
            <div className="t-grid-col">
              <img src={img7} alt="" data-fancybox="inner-gallery" />
            </div>
            <div className="t-grid-col">
              <img src={img7} alt="" data-fancybox="inner-gallery" />
            </div>
            <div className="t-grid-col">
              <img src={img7} alt="" data-fancybox="inner-gallery" />
            </div>
            <div className="t-grid-col">
              <img src={img7} alt="" data-fancybox="inner-gallery" />
            </div>
          </div>
          <div className="inner-port-cta">
            <Link
              to="https://calendly.com/awais-xkw/website-design-development-consultation-with-client?month=2023-12"
              target="__blank"
            >
              Get Free Proposal
            </Link>
          </div>
        </div>
  </section>*/}
    </div>
  );
};

export default PWC;
