import React, { useRef } from "react";
import img1 from "../../../Assets/Images/about-bnr.png";
import { Link } from "react-router-dom";
import AboutData from "../AboutData/Data";
import img2 from "../../../Assets/Images/about-mask.png";
import img3 from "../../../Assets/Images/vs-ico1.svg";
import img4 from "../../../Assets/Images/vs-ico2.svg";
import img5 from "../../../Assets/Images/vs-ico3.svg";
import img6 from "../../../Assets/Images/vs-ico4.svg";
import { motion, useAnimationFrame } from "framer-motion";
import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.min.css";
import ShareButton from "../../../ShareButton/GetFreeProposal";

const AboutMain = () => {
  const ref = useRef(null);
  useAnimationFrame((t) => {
    const y = (1 + Math.sin(t / 500)) * -20;
    ref.current.style.transform = `translateY(${y}px)`;
  });
  return (
    <div>
      <section className="inner-banner-sec">
        <div className="container">
          <div className="inner-banner-row">
            <div className="inner-banner-col1">
              <motion.h1
                initial={{ x: -400, opacity: "0" }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.6 }}
              >
                Teqnite Digital: Crafting Digital Dominance for Your Brand!
              </motion.h1>
              <motion.p
                initial={{ x: -400, opacity: "0" }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.9 }}
              >
                Welcome to Teqnite digital marketing agency , where creativity
                meets technology to craft your digital future and prominence
                online. Discover our innovative solutions and client-centric
                approach, making us your trusted partner in the dynamic world of
                digital excellence.
              </motion.p>
              <motion.p
                initial={{ x: -400, opacity: "0" }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.9 }}
              >
                As pioneers in the digital landscape, we are committed to
                sculpting unparalleled solutions for your brand’s unique needs.
                Join hands with our experts and let our creativity ignite your
                digital dominance.
              </motion.p>
              <motion.div
                initial={{ x: -400, opacity: "0" }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 1.3 }}
              >
                <ShareButton />
              </motion.div>
            </div>
            <motion.div
              className="inner-banner-col2"
              drag
              dragConstraints={{ left: 10, top: 10, right: 10, bottom: 10 }}
              style={{ cursor: "pointer" }}
              ref={ref}
            >
              <img src={img1} alt="" />
            </motion.div>
          </div>
        </div>
      </section>

      <section className="mask-details-sec mask-w-val-stacks">
        <div className="container">
          <div className="mast-details-row">
            <div className="mast-details-col1">
              <ScrollAnimation animateIn="fadeIn">
                <img src={img2} alt="" />
              </ScrollAnimation>
            </div>

            <div className="mast-details-col2">
              <ScrollAnimation animateIn="fadeIn">
                <h2>Tailored Strategies, Tailored Success</h2>
              </ScrollAnimation>
              <ScrollAnimation animateIn="fadeIn">
                <p>
                  We understand that every business is unique. That's why we
                  take the time to understand your goals, your audience, and
                  your industry before crafting a customized strategy that
                  aligns perfectly with your objectives. To build an audience
                  together, our seasoned sales team ensures seamless global
                  connectivity. Beyond office hours, we stand ready for our
                  clients’ real-time support.
                </p>
              </ScrollAnimation>
              {/* <ScrollAnimation animateIn="fadeIn">
                <p>
                  Our dedicated professionals ensure seamless global
                  connectivity. Beyond office hours, our 24/7 sales team stands
                  ready for real-time support.
                </p>
              </ScrollAnimation> */}
              {/* <ScrollAnimation animateIn="fadeIn">
                <p>Join hands with us, and let's build an audience together!</p>
              </ScrollAnimation> */}

              <div className="value-stacks-row">
                <ScrollAnimation animateIn="fadeIn">
                  <div className="value-stack-cell">
                    <div className="vs-icon">
                      <span>
                        <img src={img3} alt="" />
                      </span>
                    </div>
                    <div className="vs-text">
                      <span>450</span>
                      <p>Happy Clients</p>
                    </div>
                  </div>
                </ScrollAnimation>
                <ScrollAnimation animateIn="fadeIn">
                  <div className="value-stack-cell">
                    <div className="vs-icon">
                      <span>
                        <img src={img4} alt="" />
                      </span>
                    </div>
                    <div className="vs-text">
                      <span>48k</span>
                      <p>Projects Done</p>
                    </div>
                  </div>
                </ScrollAnimation>

                <ScrollAnimation animateIn="fadeIn">
                  <div className="value-stack-cell">
                    <div className="vs-icon">
                      <span>
                        <img src={img5} alt="" />
                      </span>
                    </div>
                    <div className="vs-text">
                      <span>95k</span>
                      <p>Hours Worked</p>
                    </div>
                  </div>
                </ScrollAnimation>
                <ScrollAnimation animateIn="fadeIn">
                  <div className="value-stack-cell">
                    <div className="vs-icon">
                      <span>
                        <img src={img6} alt="" />
                      </span>
                    </div>
                    <div className="vs-text">
                      <span>24/7</span>
                      <p>Support Available</p>
                    </div>
                  </div>
                </ScrollAnimation>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="abt-why-sec">
        <div className="container">
          <div className="abt-why-sec-title">
            <h2>How Do We Help Your Business?</h2>
          </div>

          <div className="abt-why-sec-grid">
            {AboutData.map((item, index) => (
              <div className="abt-why-cell" key={index}>
                <ScrollAnimation animateIn="fadeIn">
                  <div className="abt-why-ico">
                    <img src={item.Img} alt="" />
                  </div>
                  <h3>{item.title}</h3>
                  <p>{item.Description}</p>
                </ScrollAnimation>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default AboutMain;
