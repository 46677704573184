import React, { useRef } from "react";
import img1 from "../../Assets/Images/graphic-bnr-col.png";
import img6 from "../../Assets/Images/graphic-mask.jpg";
import img7 from "../../Assets/Images/advanced-col.jpg";
import img8 from "../../Assets/Images/gr-proce-6.svg";
import img9 from "../../Assets/Images/gr-proce-5.svg";
import img10 from "../../Assets/Images/gr-proce-4.svg";
import img11 from "../../Assets/Images/gr-proce-3.svg";
import img12 from "../../Assets/Images/gr-proce-2.svg";
import img13 from "../../Assets/Images/gr-proce-1.svg";
import { Link } from "react-router-dom";
import Happyclient from "../HappyClient/Happyclient";
import { motion } from "framer-motion";
import { useAnimationFrame } from "framer-motion";
import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.min.css";
import ScheduleFreeConsult from "../../ShareButton/ScheduleFreeConsult";
const Index = () => {
  const ref = useRef(null);
  useAnimationFrame((t) => {
    const y = (1 + Math.sin(t / 500)) * -20;
    ref.current.style.transform = `translateY(${y}px)`;
  });
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div>
      <section className="inner-banner-sec">
        <div className="container">
          <div className="inner-banner-row">
            <div className="inner-banner-col1">
              <motion.h1
                initial={{ x: -400, opacity: "0" }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.6 }}
              >
                Affordable Graphic Design Services
              </motion.h1>
              <motion.p
                initial={{ x: -400, opacity: "0" }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.9 }}
              >
                At Teqnite Graphic Design Agency, we're passionate about visual
                storytelling. Our devoted design team merges creativity with
                strategy to produce impactful visuals, ranging from
                attention-grabbing logos to user-centered UI/UX designs. Let's
                set your brand apart with an unforgettable design journey.
              </motion.p>
              {/* <motion.p
                initial={{ x: -400, opacity: "0" }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.9 }}
              >
                Let's set your brand apart with a design journey.
              </motion.p> */}

              <motion.div
                initial={{ x: -400, opacity: "0" }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 1.3 }}
              >
                <ScheduleFreeConsult />
              </motion.div>
            </div>
            <motion.div
              className="inner-banner-col2"
              drag
              dragConstraints={{ left: 10, top: 10, right: 10, bottom: 10 }}
              style={{ cursor: "pointer" }}
              ref={ref}
            >
              <img src={img1} alt="" />
            </motion.div>
          </div>
        </div>
      </section>
      <Happyclient />

      <section className="mask-details-sec PB10">
        <div className="container">
          <div className="mast-details-row">
            <div className="mast-details-col1">
              <ScrollAnimation animateIn="fadeIn">
                <img src={img6} alt="" />
              </ScrollAnimation>
            </div>
            <div className="mast-details-col2">
              <ScrollAnimation animateIn="fadeIn">
                <h2>Turn Ideas Into Timeless Designs</h2>
                <p>
                  There are no limits to creative possibilities with Teqnite's
                  graphic design services, which are backed by our expertise as
                  a leading graphic design service. Our seasoned creative
                  graphic designers breathe life into ideas with captivating
                  visuals, offering a spectrum of services from logo creation to
                  immersive 2D and 3D animations.
                </p>
                <p>
                  Focused on creating a seamless UI/UX experience, we enhance
                  your brand's digital footprint, guaranteeing a captivating and
                  unforgettable identity. Collaborate with us for design
                  superiority that surpasses conventional limits, distinguishing
                  your brand within the digital realm.
                </p>
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </section>

      <section className="advanced-sec">
        <div className="container">
          <div className="advanced-sec-row">
            <div className="advanced-col1">
              <ScrollAnimation animateIn="fadeIn">
                <h2>High-Tech Graphic Design Tools for Great Ideas</h2>
              </ScrollAnimation>
              <ScrollAnimation animateIn="fadeIn">
                <p>
                  At Teqnite, we push the boundaries of conventional design,
                  using next-level graphic design tools to craft compelling
                  narratives for your brand. Our skilled team employs advanced
                  techniques, ensuring your visual identity stands out and
                  remains ahead of the curve. Whether it's dynamic logos or
                  immersive UI/UX experiences, each creation exemplifies our
                  commitment to innovation. Explore our advanced graphic design
                  services today to enhance your brand's look and appeal online
                  for today and the years to come. Partner with Teqnite, and
                  let's shape a visually compelling future for your brand.
                </p>
              </ScrollAnimation>
              {/* <div className="achievement-pills">
                <span># 800+ Design Delivered</span>
                <span># 50+ Team Strength</span>
                <span># User-Friendly Interface</span>
                <span># 400 Happy Clients</span>
                <span># 95% Repeat business</span>
                <span># Quality Service UI/UX</span>
  </div>*/}
              <ScrollAnimation animateIn="fadeIn">
                <ScheduleFreeConsult />
              </ScrollAnimation>
            </div>
            <div className="advanced-col2">
              <ScrollAnimation animateIn="fadeIn">
                <img src={img7} alt="" />
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </section>

      <section className="offers-sec cusPd_grp">
        <div className="container">
          <div className="offers-sec-title">
            <ScrollAnimation animateIn="fadeIn">
              <h2>Teqnite's Professional Graphic Design Services</h2>
              <p>
                We deliver creative excellence through memorable designs —
                designs that speak louder than words!
              </p>
            </ScrollAnimation>
          </div>
          <div className="offers-sec-grid">
            <ScrollAnimation animateIn="fadeIn">
              <div className="s-offer-cell">
                <div className="s-offer-name">
                  <div className="s-offer-ico">
                    <img src={img8} alt="" />
                  </div>
                  <h3>Logo Design</h3>
                </div>
                <p>
                  At Teqnite, our creative logo design services are the epitome
                  of creativity and precision. We specialize in crafting iconic
                  symbols that define your brand's identity within our
                  comprehensive range of graphic design solutions. Elevate your
                  visual representation with a company logo design that
                  resonates and makes a lasting impression.
                </p>
              </div>
            </ScrollAnimation>
            <ScrollAnimation animateIn="fadeIn">
              <div className="s-offer-cell">
                <div className="s-offer-name">
                  <div className="s-offer-ico">
                    <img src={img9} alt="" />
                  </div>
                  <h3>Packaging Design</h3>
                </div>
                <p>
                  Our packaging graphic design services turn products into
                  unforgettable experiences. By blending innovation with
                  aesthetics, we bring your brand's vision to life from concept
                  to fruition. Our designs not only elevate your brand's
                  presence on store shelves but also leave a lasting impression
                  in the hands of your consumers.
                </p>
              </div>
            </ScrollAnimation>
            <ScrollAnimation animateIn="fadeIn">
              <div className="s-offer-cell">
                <div className="s-offer-name">
                  <div className="s-offer-ico">
                    <img src={img10} alt="" />
                  </div>
                  <h3>Marketing Collaterals</h3>
                </div>
                <p>
                  Boost your brand's visibility with Teqnite's marketing
                  collateral graphic design services. Be it brochures or
                  business cards, we specialize in crafting visually captivating
                  materials that make a lasting impact. Ensure your marketing
                  endeavors shine amidst the digital clutter with our
                  attention-grabbing designs.
                </p>
              </div>
            </ScrollAnimation>
            <ScrollAnimation animateIn="fadeIn">
              <div className="s-offer-cell">
                <div className="s-offer-name">
                  <div className="s-offer-ico">
                    <img src={img11} alt="" />
                  </div>
                  <h3>Custom Graphic Designs</h3>
                </div>
                <p>
                  The custom graphic design services we offer are uniquely
                  tailored to reflect your brand identity. Whether it's creating
                  bespoke logos or creating personalized marketing materials, we
                  infuse creativity and precision into every design. Our goal is
                  to provide you with a distinct and impactful visual
                  representation that perfectly aligns with your brand.
                </p>
              </div>
            </ScrollAnimation>
            <ScrollAnimation animateIn="fadeIn">
              <div className="s-offer-cell">
                <div className="s-offer-name">
                  <div className="s-offer-ico">
                    <img src={img12} alt="" />
                  </div>
                  <h3>Newsletter Designing</h3>
                </div>
                <p>
                  Benefit from the vibrant pulse of Teqnite's newsletter graphic
                  design services. Visually captivating newsletters resonate
                  with your audience and ensure your message leaves a lasting
                  impression. Elevate your email marketing efforts with our
                  innovative design solutions that create an impact.
                </p>
              </div>
            </ScrollAnimation>
            <ScrollAnimation animateIn="fadeIn">
              <div className="s-offer-cell">
                <div className="s-offer-name">
                  <div className="s-offer-ico">
                    <img src={img13} alt="" />
                  </div>
                  <h3>Corporate Design</h3>
                </div>
                <p>
                  A solid corporate identity has a significant impact on today's
                  competitive business landscape. That's why we offer expert
                  corporate graphic design services that can transform your
                  brand into a visual masterpiece. From cohesive branding to
                  professional materials, we have the expertise to create a
                  unified and polished visual representation that aligns
                  seamlessly with your corporate image.
                </p>
              </div>
            </ScrollAnimation>
          </div>
        </div>
      </section>

      <section className="hire-me-sec cusHire_grp">
        <div className="container">
          <ScrollAnimation animateIn="fadeIn">
            <div className="hire-me-block">
              <div className="hire-me-cntnt">
                <div className="hm-text">
                  <h2>Transforming Visions Into Winning Visual!</h2>
                </div>
                <div className="hm-cta">
                  <Link to="/contact-us" onClick={scrollToTop}>
                    Get In Touch
                  </Link>
                </div>
              </div>
            </div>
          </ScrollAnimation>
        </div>
      </section>
    </div>
  );
};

export default Index;
