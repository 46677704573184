import React from "react";
import { Link } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.min.css";
const GrowYourBusiness = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div>
      <ScrollAnimation animateIn="fadeIn">
        <section className="business-sec">
          <div className="container">
            <div className="business-block">
              <h2>Grow Your Business Against All Odds!</h2>
              <p>
                Empower your business to thrive against all odds with Teqnite's
                transformative Digital Marketing solutions. <br /> Elevate your
                brand, captivate your audience, and start to conquer new
                horizons today!
              </p>
              <div className="business-ctas">
                <Link to="/contact-us" onClick={scrollToTop}>
                  Request a Free Proposal
                </Link>
                <Link to="tel:+1619798 8070">+1 619 798 8070</Link>
              </div>
            </div>
          </div>
        </section>
      </ScrollAnimation>
    </div>
  );
};

export default GrowYourBusiness;
