import anpi1 from "../../../../Assets/Images/AnimationPortfolio/Scene-01.gif";
import anpi2 from "../../../../Assets/Images/AnimationPortfolio/Scene-02.gif";
import anpi3 from "../../../../Assets/Images/AnimationPortfolio/scene-03.gif";
import anpi4 from "../../../../Assets/Images/AnimationPortfolio/scene-04.gif";
import anpi5 from "../../../../Assets/Images/AnimationPortfolio/scene-05.gif";
import anpi6 from "../../../../Assets/Images/AnimationPortfolio/scene-06.gif";
import anpi7 from "../../../../Assets/Images/AnimationPortfolio/scene-07.gif";
import anpi8 from "../../../../Assets/Images/AnimationPortfolio/scene-08.gif";
const DataAimation = [
  {
    id: 1,
    img: anpi1,
  },
  {
    id: 2,
    img: anpi2,
  },
  {
    id: 3,
    img: anpi3,
  },
  {
    id: 4,
    img: anpi4,
  },
  {
    id: 5,
    img: anpi5,
  },
  {
    id: 6,
    img: anpi6,
  },
  {
    id: 7,
    img: anpi7,
  },
  {
    id: 8,
    img: anpi8,
  },
];

export default DataAimation;
