import React from "react";
import { Link } from "react-router-dom";

const ScheduleFreeConsult = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <Link to="/contact-us" onClick={scrollToTop}>
      Schedule a Free Consult
    </Link>
  );
};

export default ScheduleFreeConsult;
