import gpi1 from "../../../Assets/Images/GraphicPortfolio/port-logo-17.jpg";
import gpi2 from "../../../Assets/Images/GraphicPortfolio/port-logo-18.jpg";
import gpi3 from "../../../Assets/Images/GraphicPortfolio/port-logo-19.jpg";
import gpi4 from "../../../Assets/Images/GraphicPortfolio/port-logo-20.jpg";
import gpi5 from "../../../Assets/Images/GraphicPortfolio/port-logo-21.jpg";
import gpi6 from "../../../Assets/Images/GraphicPortfolio/port-logo-22.jpg";
import gpi7 from "../../../Assets/Images/GraphicPortfolio/port-logo-23.jpg";
import gpi8 from "../../../Assets/Images/GraphicPortfolio/port-logo-24.jpg";

const DataPort = [
  {
    id: 1,
    img: gpi1,
  },
  {
    id: 2,
    img: gpi2,
  },
  {
    id: 3,
    img: gpi3,
  },
  {
    id: 4,
    img: gpi4,
  },
  {
    id: 5,
    img: gpi5,
  },
  {
    id: 6,
    img: gpi6,
  },
  {
    id: 7,
    img: gpi7,
  },
  {
    id: 8,
    img: gpi8,
  },
];

export default DataPort;
