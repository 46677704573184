import React from "react";
import Index from "../GraphicHeader/Index";
import IndexTabs from "../DesignTabs/IndexTabs";
import WhyChooseTeqnite from "../WhyChooseTeqnite/WhyChooseTeqnite";
import Testimonial from "../../Testimonial/Testimonial";
import GrowYourBusiness from "../../Growbussnies/GrowYourBusiness";
import { Helmet } from "react-helmet";

const Designpage = () => {
  return (
    <div>
      <Helmet>
        <title>Graphic Design Services: Logo, Web Design & More!</title>
        <meta
          name="description"
          content="Elevate your brand with top-notch graphic design services. From logos to web design, we bring your vision to life with creativity and precision."
        />
        <meta
          name="keywords"
          content="affordable graphic design services, professional graphic design, custom graphic design services, graphic design agency, company logo design, creative logo design, creative graphic designers"
        />
      </Helmet>
      <Index />
      <IndexTabs />
      <WhyChooseTeqnite />
      <GrowYourBusiness />
      <Testimonial />
    </div>
  );
};

export default Designpage;
