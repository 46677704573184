import React from "react";
import img1 from "../../Assets/Images/vs-ico1.svg";
import img2 from "../../Assets/Images/vs-ico2.svg";
import img3 from "../../Assets/Images/vs-ico3.svg";
import img4 from "../../Assets/Images/vs-ico4.svg";
import "animate.css/animate.min.css";
import ScrollAnimation from "react-animate-on-scroll";
const Happyclient = () => {
  return (
    <div>
      <section className="value-stacks">
        <div className="container">
          <div className="value-stacks-row">
            <ScrollAnimation animateIn="fadeIn">
              <div className="value-stack-cell">
                <div className="vs-icon">
                  <span>
                    <img src={img1} alt="" />
                  </span>
                </div>
                <div className="vs-text">
                  <span>450</span>
                  <p>Happy Clients</p>
                </div>
              </div>
            </ScrollAnimation>
            <ScrollAnimation animateIn="fadeIn">
              <div className="value-stack-cell">
                <div className="vs-icon">
                  <span>
                    <img src={img2} alt="" />
                  </span>
                </div>
                <div className="vs-text">
                  <span>48k</span>
                  <p>Projects Done</p>
                </div>
              </div>
            </ScrollAnimation>
            <ScrollAnimation animateIn="fadeIn">
              <div className="value-stack-cell">
                <div className="vs-icon">
                  <span>
                    <img src={img3} alt="" />
                  </span>
                </div>
                <div className="vs-text">
                  <span>95k</span>
                  <p>Hours Worked</p>
                </div>
              </div>
            </ScrollAnimation>
            <ScrollAnimation animateIn="fadeIn">
              <div className="value-stack-cell">
                <div className="vs-icon">
                  <span>
                    <img src={img4} alt="" />
                  </span>
                </div>
                <div className="vs-text">
                  <span>24/7</span>
                  <p>Support Available</p>
                </div>
              </div>
            </ScrollAnimation>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Happyclient;
