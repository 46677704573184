import img1 from "../../../Assets/Images/GallaryImages/img1.jpg";
import img2 from "../../../Assets/Images/GallaryImages/img2.jpg";
import img3 from "../../../Assets/Images/GallaryImages/img3.jpg";
import img4 from "../../../Assets/Images/GallaryImages/img4.jpg";
import img5 from "../../../Assets/Images/GallaryImages/img5.jpg";
import img6 from "../../../Assets/Images/GallaryImages/img6.jpg";
import img7 from "../../../Assets/Images/GallaryImages/img7.jpg";
import img8 from "../../../Assets/Images/GallaryImages/img8.jpg";
import img9 from "../../../Assets/Images/GallaryImages/gallary1.jpg";
import img10 from "../../../Assets/Images/GallaryImages/img9.jpg";
import img11 from "../../../Assets/Images/GallaryImages/img10.jpg";
import img12 from "../../../Assets/Images/GallaryImages/img11.jpg";
import img13 from "../../../Assets/Images/GallaryImages/img12.jpg";
import img14 from "../../../Assets/Images/GallaryImages/img13.jpg";
import img15 from "../../../Assets/Images/GallaryImages/img14.jpg";
import img16 from "../../../Assets/Images/GallaryImages/img15.jpg";
import img17 from "../../../Assets/Images/GallaryImages/img16.jpg";
import img18 from "../../../Assets/Images/GallaryImages/img17.jpg";
import img19 from "../../../Assets/Images/GallaryImages/img18.jpg";
import img20 from "../../../Assets/Images/GallaryImages/img19.jpg";
import img21 from "../../../Assets/Images/GallaryImages/img20.jpg";

const GallaryData = [
  {
    id: 1,
    Img: img1,
  },
  {
    id: 2,
    Img: img2,
  },
  {
    id: 3,
    Img: img3,
  },
  {
    id: 4,
    Img: img4,
  },
  {
    id: 5,
    Img: img5,
  },
  {
    id: 6,
    Img: img6,
  },
  {
    id: 7,
    Img: img7,
  },
  {
    id: 8,
    Img: img8,
  },
  {
    id: 9,
    Img: img9,
  },
  {
    id: 10,
    Img: img10,
  },
  {
    id: 11,
    Img: img11,
  },
  {
    id: 21,
    Img: img21,
  },
  {
    id: 12,
    Img: img12,
  },
  {
    id: 13,
    Img: img13,
  },
  {
    id: 14,
    Img: img14,
  },
  {
    id: 15,
    Img: img15,
  },
  {
    id: 16,
    Img: img16,
  },
  {
    id: 17,
    Img: img17,
  },
  {
    id: 18,
    Img: img18,
  },
  {
    id: 19,
    Img: img19,
  },
  {
    id: 20,
    Img: img20,
  },
];

export default GallaryData;
