import React, { useState, useEffect } from "react";
import "./Navbar.css";
import { Link, useLocation } from "react-router-dom";
import Logo from "../Assets/Images/head-logo.svg";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  // const [scrolled, setScrolled] = useState(false);
  const location = useLocation();
  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  // const handleScroll = () => {
  //   const offset = window.scrollY;
  //   setScrolled(offset > 100);
  // };

  // useEffect(() => {
  //   window.addEventListener("scroll", handleScroll);

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  // const navbarClasses = ["navbar", scrolled ? "scrolled sticky" : ""];

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const isActive = (path) => {
    return location.pathname === path ? "activeis" : "";
  };

  return (
    <header>
      {/* <nav className={`head ${navbarClasses.join(" ")}`}> */}
      <nav className="head">
        <div className="container">
          <div className="head_cntnt_hldr">
            <div className="head_logo">
              <Link
                to="/"
                onClick={() => {
                  scrollToTop();
                  toggleNavbar();
                }}
                className={`${isOpen ? "close" : ""}`}
              >
                <img src={Logo} alt="" />
              </Link>
            </div>
            <div className="head_nav_hldr">
              <div className={`head_nav_clmn ${isOpen ? "active" : ""}`}>
                <ul>
                  <li className={isActive("/")}>
                    <Link
                      to="/"
                      onClick={() => {
                        scrollToTop();
                        toggleNavbar();
                      }}
                      className={`${isOpen ? "close" : ""}`}
                    >
                      Home
                    </Link>
                  </li>
                  <li className={isActive("/about-us")}>
                    <Link
                      to="/about-us"
                      onClick={() => {
                        scrollToTop();
                        toggleNavbar();
                      }}
                      className={`${isOpen ? "close" : ""}`}
                    >
                      About
                    </Link>
                  </li>

                  <li className="serdrop" id="dropdown">
                    <a className="dropicon" href="#services">
                      Services
                    </a>
                    <div className="dropdownmenu">
                      <ul>
                        <li className={isActive("/graphic-design")}>
                          <Link
                            to="/graphic-design"
                            onClick={() => {
                              scrollToTop();
                              toggleNavbar();
                            }}
                            className={`${isOpen ? "close" : ""}`}
                          >
                            Graphic Designing
                          </Link>
                        </li>
                        <li className={isActive("/animation")}>
                          <Link
                            to="/animation"
                            onClick={() => {
                              scrollToTop();
                              toggleNavbar();
                            }}
                            className={`${isOpen ? "close" : ""}`}
                          >
                            2D & 3D Animation
                          </Link>
                        </li>
                        <li className={isActive("/web-development")}>
                          <Link
                            to="/web-development"
                            onClick={() => {
                              scrollToTop();
                              toggleNavbar();
                            }}
                            className={`${isOpen ? "close" : ""}`}
                          >
                            Web Development
                          </Link>
                        </li>
                        <li className={isActive("/app-development")}>
                          <Link
                            to="/app-development"
                            onClick={() => {
                              scrollToTop();
                              toggleNavbar();
                            }}
                            className={`${isOpen ? "close" : ""}`}
                          >
                            App Development
                          </Link>
                        </li>
                        <li className={isActive("/digital-marketing")}>
                          <Link
                            to="/digital-marketing"
                            onClick={() => {
                              scrollToTop();
                              toggleNavbar();
                            }}
                            className={`${isOpen ? "close" : ""}`}
                          >
                            Digital Marketing
                          </Link>
                        </li>
                        <li className={isActive("/branding")}>
                          <Link
                            to="/branding"
                            onClick={() => {
                              scrollToTop();
                              toggleNavbar();
                            }}
                            className={`${isOpen ? "close" : ""}`}
                          >
                            Branding
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li className={isActive("/our-projects")}>
                    <Link
                      to="/our-projects"
                      onClick={() => {
                        scrollToTop();
                        toggleNavbar();
                      }}
                      className={`${isOpen ? "close" : ""}`}
                    >
                      Projects
                    </Link>
                  </li>
                  {/* <li className={isActive("/testimonials")}>
                    <Link
                      to="/testimonials"
                      onClick={() => {
                        scrollToTop();
                        toggleNavbar();
                      }}
                      className={`${isOpen ? "close" : ""}`}
                    >
                      Testimonials
                    </Link>
                  </li> */}
                  <li className={isActive("/contact-us")}>
                    <Link
                      to="/contact-us"
                      onClick={() => {
                        scrollToTop();
                        toggleNavbar();
                      }}
                      className={`Contactmenu ${isOpen ? "close" : ""}`}
                    >
                      Contact Us
                    </Link>
                  </li>
                </ul>
                <div className="navbar-toggle" onClick={toggleNavbar}>
                  <span className="nav-toggle"></span>
                </div>
              </div>
            </div>
            <div className="head_cta_btn">
              <Link
                to="contact-us"
                onClick={() => {
                  scrollToTop();
                  toggleNavbar();
                }}
                className={`${isOpen ? "close" : ""}`}
              >
                Contact Us
              </Link>
            </div>
            <div className="navbar-toggle" onClick={toggleNavbar}>
              <span className={`nav-toggle ${isOpen ? "close" : ""}`}></span>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
