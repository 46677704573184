import React, { useRef } from "react";
import img1 from "../../../Assets/Images/branding-bnr.png";
import img2 from "../../../Assets/Images/brandingmask.png";
import img3 from "../../../Assets/Images/brandingser-1.svg";
import img4 from "../../../Assets/Images/brandingser-2.svg";
import img5 from "../../../Assets/Images/brandingser-3.svg";
import { Link } from "react-router-dom";
import Happyclient from "../../../GraphicTemplate/HappyClient/Happyclient";
import { motion, useAnimationFrame } from "framer-motion";
import PWC from "../PortfolioWhychoose/PWC";
// import BrandTabs from "../BrandTabs/BrandTabs";

import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.min.css";
import GetFreeProposal from "../../../ShareButton/GetFreeProposal";

const Main = () => {
  const ref = useRef(null);
  useAnimationFrame((t) => {
    const y = (1 + Math.sin(t / 500)) * -20;
    ref.current.style.transform = `translateY(${y}px)`;
  });
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div>
      <section className="inner-banner-sec">
        <div className="container">
          <div className="inner-banner-row">
            <div className="inner-banner-col1">
              <motion.h1
                initial={{ x: -400, opacity: "0" }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.6 }}
              >
                Hire the Top Branding Agency to Enhance Your Brand's Visibility
              </motion.h1>
              <motion.p
                initial={{ x: -400, opacity: "0" }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.9 }}
              >
                Visibility is key in today's vast digital landscape. Agree?
                Leveraging the latest technologies and trends, Teqnite business
                branding services deploy targeted campaigns across multiple
                channels to ensure maximum exposure for your brand. Whether it's
                through captivating digital branding, remarkable content,
                engaging social media campaigns, or strategic SEO tactics, we're
                dedicated to keeping your brand in the spotlight and ahead of
                the curve.
              </motion.p>
              {/* <motion.p
                initial={{ x: -400, opacity: "0" }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.9 }}
              >
                <li>Eye-catching, attention-grabbing visuals</li>
                <li>Innovative and powerful branding solutions </li>
              </motion.p> */}
              <motion.div
                initial={{ x: -400, opacity: "0" }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 1.3 }}
              >
                <GetFreeProposal />
              </motion.div>
            </div>
            <motion.div
              className="inner-banner-col2"
              drag
              dragConstraints={{ left: 10, top: 10, right: 10, bottom: 10 }}
              style={{ cursor: "pointer" }}
              ref={ref}
            >
              <img src={img1} alt="" />
            </motion.div>
          </div>
        </div>
      </section>
      <Happyclient />

      <section className="mask-details-sec">
        <div className="container">
          <div className="mast-details-row">
            <div className="mast-details-col1">
              <ScrollAnimation animateIn="fadeIn">
                <img src={img2} alt="" />
              </ScrollAnimation>
            </div>
            <div className="mast-details-col2">
              <ScrollAnimation animateIn="fadeIn">
                <h2>Teqnite's Signature Approach to Brand Identity Design</h2>
              </ScrollAnimation>
              <ScrollAnimation animateIn="fadeIn">
                <p>
                  As a leading branding agency, Teqnite is dedicated to As a
                  leading branding company, Teqnite is dedicated to providing
                  businesses with a distinctive identity. We reinforce your
                  brand with robust, custom branding designs that set you apart.
                  Beyond just boosting visibility, we believe in fostering
                  meaningful, lasting connections with your audience. Through
                  thoughtful engagement and interactive experiences, we
                  cultivate brand loyalty and advocacy that extends far beyond a
                  single transaction. With Teqnite by your side, you'll not only
                  attract customers but also <b>create brand ambassadors.</b>
                </p>
                <p>
                  When it comes to enhancing your brand's visibility, settle for
                  nothing but the best. Elevate your business with the premier
                  branding services—contact us today!
                </p>
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </section>

      <section className="offers-sec">
        <div className="container">
          <div className="offers-sec-title">
            <ScrollAnimation animateIn="fadeIn">
              <h2>Teqnite Branding Agency Services</h2>
              <p>Maximizing Visibility — Amplifying Impact</p>
            </ScrollAnimation>
          </div>
          <div className="offers-sec-grid">
            <div className="s-offer-cell">
              <ScrollAnimation animateIn="fadeIn">
                <div className="s-offer-name">
                  <div className="s-offer-ico">
                    <img src={img3} alt="" />
                  </div>
                  <h3>Stationery Design Services</h3>
                </div>
                <p>
                  We create cohesive and visually appealing stationary designs
                  that not only add a touch of aesthetics but also represent
                  your brand's values and inspirations. From business cards to
                  letterheads, we ensure every piece reflects the essence of
                  your brand, leaving a lasting impression on clients and
                  partners. Elevate your brand's professionalism and leave a
                  lasting impression with our premium stationery branding design
                  services.
                </p>
              </ScrollAnimation>
            </div>
            <div className="s-offer-cell">
              <ScrollAnimation animateIn="fadeIn">
                <div className="s-offer-name">
                  <div className="s-offer-ico">
                    <img src={img4} alt="" />
                  </div>
                  <h3>Print And Packaging Services</h3>
                </div>
                <p>
                  Forget boring boxes and bland brochures! Teqnite's print and
                  packaging are like a megaphone for your brand, making it shout
                  with personality at every turn. Our design pros are all about
                  creating eye-catching product packaging and powerful marketing
                  materials that blend killer looks with strategic smarts. So
                  leave the generic behind and choose Teqnite for print and
                  packaging that makes your audience stop, stare, and remember.
                </p>
              </ScrollAnimation>
            </div>
            <div className="s-offer-cell">
              <ScrollAnimation animateIn="fadeIn">
                <div className="s-offer-name">
                  <div className="s-offer-ico">
                    <img src={img5} alt="" />
                  </div>
                  <h3>Social Media Design Services</h3>
                </div>
                <p>
                  Tired of your social media looking like everyone else's?
                  Teqnite's design wizards are here to turn your online presence
                  into a total showstopper! Our social media experts create
                  killer graphics and layouts that are made for each platform so
                  your brand looks amazing no matter where you scroll. We'll
                  craft a consistent style for your brand that grabs attention
                  and tells your story in a way that resonates with your
                  audience.
                </p>
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </section>
      <section className="hire-me-sec">
        <div className="container">
          <ScrollAnimation animateIn="fadeIn">
            <div className="hire-me-block">
              <div className="hire-me-cntnt">
                <div className="hm-text">
                  <h2>Enhance Your Branding Game!</h2>
                </div>
                <div className="hm-cta">
                  <Link to="/contact-us" onClick={scrollToTop}>
                    Get In Touch
                  </Link>
                </div>
              </div>
            </div>
          </ScrollAnimation>
        </div>
      </section>
      {/*<BrandTabs />*/}
      <PWC />
    </div>
  );
};

export default Main;
