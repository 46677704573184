import React from "react";
import BlogInnerMain from "../BlogInnerMain/BlogInnerMain";
import GrowYourBusiness from "../../../Growbussnies/GrowYourBusiness";
import Testimonial from "../../../Testimonial/Testimonial";
const Index = () => {
  return (
    <div>
      <BlogInnerMain />
      <GrowYourBusiness />
      <Testimonial />
    </div>
  );
};

export default Index;
