import React, { useEffect, useRef, useState } from "react";
import "./contact.css";
import img1 from "../../../Assets/Images/contact-us.png";
import { motion, useAnimationFrame } from "framer-motion";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import emailjs from "@emailjs/browser";
const ContactMain = () => {
  const ref = useRef(null);
  useAnimationFrame((t) => {
    const y = (1 + Math.sin(t / 500)) * -20;
    ref.current.style.transform = `translateY(${y}px)`;
  });
  useEffect(() => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.async = true;
    script.src = "https://assets.calendly.com/assets/external/widget.js";
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    const serviceId = "service_exjx1yj";
    const templateId = "template_pl0ruyh";
    const publicKey = "l3jevZCEYr6pA04G7";

    const templateParams = {
      form_name: firstname,
      form_lastname: lastname,
      form_email: email,
      form_phone: phone,
      message: message,
      to_name: "Teqnite",
    };

    emailjs
      .send(serviceId, templateId, templateParams, publicKey)
      .then((response) => {
        if (response.status === 200) {
          console.log("Email sent successfully");
          setFirstname("");
          setLastname("");
          setEmail("");
          setPhone("");
          setMessage("");
          toast.success("Email sent successfully!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
            theme: "colored",
          });
        }
      })
      .catch((error) => {
        console.error("Unexpected response");
        toast.error(
          "Unexpected response from server. Please try again later.",
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
            theme: "colored",
          }
        );
      });
  };

  return (
    <div>
      <section className="inner-banner-sec">
        <div className="container">
          <div className="inner-banner-row">
            <div className="inner-banner-col1">
              <motion.h1
                initial={{ x: -400, opacity: "0" }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.6 }}
              >
                Contact Us
              </motion.h1>
              <motion.p
                initial={{ x: -400, opacity: "0" }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.9 }}
              >
                We are the storytellers of tomorrow - explicitly changing how
                you communicate your story
              </motion.p>
              {/* <motion.div
                initial={{ x: -400, opacity: "0" }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 1.3 }}
              >
                <GetFreeProposal />
              </motion.div> */}
            </div>
            <motion.div
              className="inner-banner-col2"
              drag
              dragConstraints={{ left: 10, top: 10, right: 10, bottom: 10 }}
              style={{ cursor: "pointer" }}
              ref={ref}
            >
              <img src={img1} alt="" />
            </motion.div>
          </div>
        </div>
      </section>

      <section className="Contact_us_sec">
        <div className="container">
          <div className="svcs-title">
            <h2>Get Started Today!</h2>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="grid__form">
              <div className="input-forms">
                <input
                  type="text"
                  value={firstname}
                  required
                  onChange={(e) => setFirstname(e.target.value)}
                  placeholder="First Name"
                />
              </div>
              <div className="input-forms">
                <input
                  type="text"
                  required
                  value={lastname}
                  onChange={(e) => setLastname(e.target.value)}
                  placeholder="Last Name"
                />
              </div>

              <div className="input-forms">
                <input
                  type="email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Your Email"
                />
              </div>
              <div className="input-forms">
                <input
                  type="number"
                  required
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  placeholder="Your Phone"
                />
              </div>
              <div className="textarea-forms">
                <textarea
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  required
                  placeholder="Your Message"
                ></textarea>
              </div>
            </div>

            <div class="pf-t-cta">
              <button type="submit">Send Message</button>
            </div>
          </form>
        </div>
      </section>

      {/* <section className="Contact_us_sec">
        <div className="container">
          <div className="svcs-title">
            <h2>Get Started Today!</h2>
          </div>
          <div
            className="calendly-inline-widget"
            data-url="https://calendly.com/awais-xkw/website-design-development-consultation-with-client"
            style={{ Width: "100% !important", height: "700px" }}
          ></div>
        </div>
      </section> */}
    </div>
  );
};

export default ContactMain;
