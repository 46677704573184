import React from "react";
import WebHeader from "../WebDesignPage/WebHeader";
// import IndexTabs from "../../../GraphicTemplate/DesignTabs/IndexTabs";
// import WhyChooseTeqnite from "../../../GraphicTemplate/WhyChooseTeqnite/WhyChooseTeqnite";
import GrowYourBusiness from "../../../Growbussnies/GrowYourBusiness";
import Testimonial from "../../../Testimonial/Testimonial";
import { Helmet } from "react-helmet";

const index = () => {
  return (
    <div>
      <Helmet>
        <title>Web Development Services: Shopify, WordPress & More!</title>
        <meta
          name="description"
          content="Upgrade your business with expert web development services. Specializing in Shopify, WordPress, and more, we craft seamless digital experiences for businesses."
        />
        <meta
          name="keywords"
          content="web design company, web design agency, custom web design services, creative web design services, affordable web design services, responsive web design services, e-commerce website design services, best website design, modern website design services, web design services online"
        />
      </Helmet>
      <WebHeader />
      <GrowYourBusiness />
      <Testimonial />
    </div>
  );
};

export default index;
