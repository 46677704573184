import React, { useEffect } from "react";
import img1 from "../Assets/Images/tab-ico1.svg";
import img2 from "../Assets/Images/tab-ico4.svg";
import img3 from "../Assets/Images/tab-ico7.svg";
import img4 from "../Assets/Images/tab-ico6.svg";

import gpi1 from "../Assets/Images/GraphicPortfolio/port-logo-17.jpg";
import gpi2 from "../Assets/Images/GraphicPortfolio/port-logo-18.jpg";
import gpi3 from "../Assets/Images/GraphicPortfolio/port-logo-19.jpg";
import gpi4 from "../Assets/Images/GraphicPortfolio/port-logo-20.jpg";
import gpi5 from "../Assets/Images/GraphicPortfolio/port-logo-21.jpg";
import gpi6 from "../Assets/Images/GraphicPortfolio/port-logo-22.jpg";
import gpi7 from "../Assets/Images/GraphicPortfolio/port-logo-23.jpg";
import gpi8 from "../Assets/Images/GraphicPortfolio/port-logo-24.jpg";

import wpi1 from "../Assets/Images/WebPortfolio/web-port-17.jpg";
import wpi2 from "../Assets/Images/WebPortfolio/web-port-18.jpg";
import wpi3 from "../Assets/Images/WebPortfolio/web-port-19.jpg";
import wpi4 from "../Assets/Images/WebPortfolio/web-port-20.jpg";
import wpi5 from "../Assets/Images/WebPortfolio/web-port-21.jpg";
import wpi6 from "../Assets/Images/WebPortfolio/web-port-22.jpg";
import wpi7 from "../Assets/Images/WebPortfolio/web-port-23.jpg";
import wpi8 from "../Assets/Images/WebPortfolio/web-port-24.jpg";

import bpi1 from "../Assets/Images/BrandingPortfolio/branding-port-11.jpg";
import bpi2 from "../Assets/Images/BrandingPortfolio/branding-port-12.jpg";
import bpi3 from "../Assets/Images/BrandingPortfolio/branding-port-13.jpg";
import bpi4 from "../Assets/Images/BrandingPortfolio/branding-port-14.jpg";
import bpi5 from "../Assets/Images/BrandingPortfolio/branding-port-15.jpg";
import bpi6 from "../Assets/Images/BrandingPortfolio/branding-port-16.jpg";
import bpi7 from "../Assets/Images/BrandingPortfolio/branding-port-17.jpg";
import bpi8 from "../Assets/Images/BrandingPortfolio/branding-port-18.jpg";

import anpi1 from "../Assets/Images/AnimationPortfolio/Scene-01.gif";
import anpi2 from "../Assets/Images/AnimationPortfolio/Scene-02.gif";
import anpi3 from "../Assets/Images/AnimationPortfolio/scene-03.gif";
import anpi4 from "../Assets/Images/AnimationPortfolio/scene-04.gif";
import anpi5 from "../Assets/Images/AnimationPortfolio/scene-05.gif";
import anpi6 from "../Assets/Images/AnimationPortfolio/scene-06.gif";
import anpi7 from "../Assets/Images/AnimationPortfolio/scene-07.gif";
import anpi8 from "../Assets/Images/AnimationPortfolio/scene-08.gif";
import ShareButton from "../ShareButton/GetFreeProposal";

import { Link } from "react-router-dom";
const Portfolio = () => {
  useEffect(() => {
    const tabsNav = document.querySelectorAll(".pf-tab-btns ul li");
    const tabsContent = document.querySelectorAll(
      ".pf-tab-content-holder .pf-tab-content"
    );

    tabsNav.forEach((items, ind) => {
      items.addEventListener("click", () => {
        tabsNav.forEach((remove) => {
          remove.classList.remove("active");
        });

        items.classList.add("active");

        tabsContent.forEach((content) => {
          content.classList.remove("active");
        });

        tabsContent[ind].classList.add("active");
      });
    });
  }, []);

  return (
    <div>
      <section className="pf-tabs-section">
        <div className="container">
          <div className="pf-t-tilte">
            <h2>Like What You See?</h2>
            <p>
              So, you're ready to boost your business. Get a fresh new look for
              your brand today!
            </p>
          </div>
          <div className="pf-t-cntnt-holder">
            <div className="pf-tab-btns">
              <ul>
                <li className="active">
                  <div className="pf-tab-ico">
                    <img src={img1} alt="" />
                  </div>
                  <span>Graphic Design</span>
                </li>
                <li>
                  <div className="pf-tab-ico">
                    <img src={img2} alt="" />
                  </div>
                  <span>Web Development</span>
                </li>
                <li>
                  <div className="pf-tab-ico">
                    <img src={img3} alt="" />
                  </div>
                  <span>Branding</span>
                </li>
                <li>
                  <div className="pf-tab-ico">
                    <img src={img4} alt="" />
                  </div>
                  <span>2D & 3D Animation</span>
                </li>
              </ul>
            </div>
            <div className="pf-tab-content-holder">
              <div className="pf-tab-content active">
                <div className="tabs-sec-grid">
                  <div className="t-grid-col">
                    <img src={gpi1} alt="" data-fancybox="gallery-1" />
                  </div>
                  <div className="t-grid-col">
                    <img src={gpi2} alt="" data-fancybox="gallery-1" />
                  </div>
                  <div className="t-grid-col">
                    <img src={gpi3} alt="" data-fancybox="gallery-1" />
                  </div>
                  <div className="t-grid-col">
                    <img src={gpi4} alt="" data-fancybox="gallery-1" />
                  </div>
                  <div className="t-grid-col">
                    <img src={gpi5} alt="" data-fancybox="gallery-1" />
                  </div>
                  <div className="t-grid-col">
                    <img src={gpi6} alt="" data-fancybox="gallery-1" />
                  </div>
                  <div className="t-grid-col">
                    <img src={gpi7} alt="" data-fancybox="gallery-1" />
                  </div>
                  <div className="t-grid-col">
                    <img src={gpi8} alt="" data-fancybox="gallery-1" />
                  </div>
                </div>
              </div>
              <div className="pf-tab-content ">
                <div className="tabs-sec-grid web-hov">
                  <div className="t-grid-col">
                    <img src={wpi1} alt="" data-fancybox="gallery-2" />
                  </div>
                  <div className="t-grid-col">
                    <img src={wpi2} alt="" data-fancybox="gallery-2" />
                  </div>
                  <div className="t-grid-col">
                    <img src={wpi3} alt="" data-fancybox="gallery-2" />
                  </div>
                  <div className="t-grid-col">
                    <img src={wpi4} alt="" data-fancybox="gallery-2" />
                  </div>
                  <div className="t-grid-col">
                    <img src={wpi5} alt="" data-fancybox="gallery-2" />
                  </div>
                  <div className="t-grid-col">
                    <img src={wpi6} alt="" data-fancybox="gallery-2" />
                  </div>
                  <div className="t-grid-col">
                    <img src={wpi7} alt="" data-fancybox="gallery-2" />
                  </div>
                  <div className="t-grid-col">
                    <img src={wpi8} alt="" data-fancybox="gallery-2" />
                  </div>
                </div>
              </div>
              <div className="pf-tab-content ">
                <div className="tabs-sec-grid">
                  <div className="t-grid-col">
                    <img src={bpi1} alt="" data-fancybox="gallery-3" />
                  </div>
                  <div className="t-grid-col">
                    <img src={bpi2} alt="" data-fancybox="gallery-3" />
                  </div>
                  <div className="t-grid-col">
                    <img src={bpi3} alt="" data-fancybox="gallery-3" />
                  </div>
                  <div className="t-grid-col">
                    <img src={bpi4} alt="" data-fancybox="gallery-3" />
                  </div>
                  <div className="t-grid-col">
                    <img src={bpi5} alt="" data-fancybox="gallery-3" />
                  </div>
                  <div className="t-grid-col">
                    <img src={bpi6} alt="" data-fancybox="gallery-3" />
                  </div>
                  <div className="t-grid-col">
                    <img src={bpi7} alt="" data-fancybox="gallery-3" />
                  </div>
                  <div className="t-grid-col">
                    <img src={bpi8} alt="" data-fancybox="gallery-3" />
                  </div>
                </div>
              </div>
              <div className="pf-tab-content ">
                <div className="tabs-sec-grid">
                  <div className="t-grid-col">
                    <img src={anpi1} alt="" data-fancybox="gallery-4" />
                  </div>
                  <div className="t-grid-col">
                    <img src={anpi2} alt="" data-fancybox="gallery-4" />
                  </div>
                  <div className="t-grid-col">
                    <img src={anpi3} alt="" data-fancybox="gallery-4" />
                  </div>
                  <div className="t-grid-col">
                    <img src={anpi4} alt="" data-fancybox="gallery-4" />
                  </div>
                  <div className="t-grid-col">
                    <img src={anpi5} alt="" data-fancybox="gallery-4" />
                  </div>
                  <div className="t-grid-col">
                    <img src={anpi6} alt="" data-fancybox="gallery-4" />
                  </div>
                  <div className="t-grid-col">
                    <img src={anpi7} alt="" data-fancybox="gallery-4" />
                  </div>
                  <div className="t-grid-col">
                    <img src={anpi8} alt="" data-fancybox="gallery-4" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="pf-t-cta">
            <ShareButton />
          </div>
        </div>
      </section>
    </div>
  );
};

export default Portfolio;
