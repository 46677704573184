import React from "react";
import Config from "./config/Navigations";
import { motion, useScroll, useSpring } from "framer-motion";

const App = () => {
  const { scrollYProgress } = useScroll();
  const scaleX = useSpring(scrollYProgress, {
    stiffness: 100,
    damping: 30,
    restDelta: 0.001,
  });

  return (
    <div>
      <motion.div className="progress-bar" style={{ scaleX }}></motion.div>
      <Config />
    </div>
  );
};

export default App;
