import React, { useEffect } from "react";
import img1 from "../../../Assets/Images/app-proces-1.svg";
import img2 from "../../../Assets/Images/app-proces-2.svg";
import img3 from "../../../Assets/Images/app-proces-3.svg";
import img4 from "../../../Assets/Images/app-proces-4.svg";
import apptabs1 from "../../../Assets/Images/apptabsproces-1.png";
import apptabs2 from "../../../Assets/Images/apptabsproces-2.png";
import apptabs3 from "../../../Assets/Images/apptabsproces-3.png";
import apptabs4 from "../../../Assets/Images/apptabsproces-4.png";
const TabsApp = () => {
  useEffect(() => {
    const tabsNav = document.querySelectorAll(".process-tab-btns ul li");
    const tabsContent = document.querySelectorAll(
      ".process-tabs-cntnt .p-tab-cntnt"
    );

    tabsNav.forEach((items, ind) => {
      items.addEventListener("click", () => {
        tabsNav.forEach((remove) => {
          remove.classList.remove("active");
        });

        items.classList.add("active");

        tabsContent.forEach((content) => {
          content.classList.remove("active");
        });

        tabsContent[ind].classList.add("active");
      });
    });
  }, []);
  return (
    <div>
      <section className="process-tabs-sec">
        <div className="container">
          <div className="process-sec-title">
            <h2>Our Mobile App Development Process</h2>
            <p>
              Teqnite's streamlined process, honed through years of experience,
              ensures every app we build is designed for success.
            </p>
          </div>
          <div className="process-sec-cntnt">
            <div className="process-tab-btns">
              <ul>
                <li className="active">
                  <div className="p-tab-ico">
                    <img src={img1} alt="" />
                  </div>
                  <span>Data Analysis</span>
                </li>
                <li>
                  <div className="p-tab-ico">
                    <img src={img2} alt="" />
                  </div>
                  <span>Prototype</span>
                </li>
                <li>
                  <div className="p-tab-ico">
                    <img src={img3} alt="" />
                  </div>
                  <span>Quality Assurance</span>
                </li>
                <li>
                  <div className="p-tab-ico">
                    <img src={img4} alt="" />
                  </div>
                  <span>Deployment</span>
                </li>
              </ul>
            </div>
            <div className="process-tabs-cntnt">
              <div className="p-tab-cntnt active">
                <div className="p-tab-c1">
                  <span>01</span>
                  <p>
                    We first become your visionary partners, dissecting your
                    concept to ensure it has the potential to thrive. We don't
                    just analyze; we collaborate, digging deep to understand
                    your unique goals and pave the way for an app development
                    journey built for success.
                  </p>
                </div>
                <div className="p-tab-c2">
                  <img src={apptabs1} alt="" />
                </div>
              </div>
              <div className="p-tab-cntnt">
                <div className="p-tab-c1">
                  <span>02</span>
                  <p>
                    This is where your idea jumps from brain wave to hands-on
                    magic. In this stage, we build a clickable, touchable
                    version of your app. You can interact with your app's design
                    and functions and see your vision come alive. No more
                    guesswork, just direct feedback to make sure your app is
                    smooth, intuitive, and awesome.
                  </p>
                </div>
                <div className="p-tab-c2">
                  <img src={apptabs2} alt="" />
                </div>
              </div>
              <div className="p-tab-cntnt">
                <div className="p-tab-c1">
                  <span>03</span>
                  <p>
                    In this phase, our committed team conducts thorough testing
                    on all aspects of your app. From functionality and
                    performance to security measures, we carefully examine every
                    detail, guaranteeing a smooth user experience and delivering
                    a top-tier, dependable application.
                  </p>
                </div>
                <div className="p-tab-c2">
                  <img src={apptabs3} alt="" />
                </div>
              </div>
              <div className="p-tab-cntnt">
                <div className="p-tab-c1">
                  <span>04</span>
                  <p>
                    Here, your app is poised to greet the world. We meticulously
                    shift your app to a live environment, guaranteeing a
                    seamless and flawless launch. This marks the realization of
                    your vision, now accessible to users globally.
                  </p>
                </div>
                <div className="p-tab-c2">
                  <img src={apptabs4} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default TabsApp;
