import React, { useEffect } from "react";
import img1 from "../../../Assets/Images/grp-proce-tabs-1.svg";
import img2 from "../../../Assets/Images/web-ser-proc-1.svg";
import img3 from "../../../Assets/Images/web-ser-proc-2.svg";
import img4 from "../../../Assets/Images/web-ser-proc-3.svg";
import tabsweb1 from "../../../Assets/Images/webprocestab-1.png";
import tabsweb2 from "../../../Assets/Images/webprocestab-4.png";
import tabsweb3 from "../../../Assets/Images/webprocestab-3.png";
import tabsweb4 from "../../../Assets/Images/webprocestab-2.png";
const WDTabs = () => {
  useEffect(() => {
    const tabsNav = document.querySelectorAll(".process-tab-btns ul li");
    const tabsContent = document.querySelectorAll(
      ".process-tabs-cntnt .p-tab-cntnt"
    );

    tabsNav.forEach((items, ind) => {
      items.addEventListener("click", () => {
        tabsNav.forEach((remove) => {
          remove.classList.remove("active");
        });

        items.classList.add("active");

        tabsContent.forEach((content) => {
          content.classList.remove("active");
        });

        tabsContent[ind].classList.add("active");
      });
    });
  }, []);
  return (
    <div>
      <section className="process-tabs-sec">
        <div className="container">
          <div className="process-sec-title">
            <h2>Our Web Design And Development Service Process</h2>
            <p>
              Elevate your digital presence with Teqnite's Web Design and
              Development Service Process.
            </p>
          </div>
          <div className="process-sec-cntnt">
            <div className="process-tab-btns">
              <ul>
                <li className="active">
                  <div className="p-tab-ico">
                    <img src={img1} alt="" />
                  </div>
                  <span>Collecting Requirements</span>
                </li>
                <li>
                  <div className="p-tab-ico">
                    <img src={img2} alt="" />
                  </div>
                  <span>Web Development</span>
                </li>
                <li>
                  <div className="p-tab-ico">
                    <img src={img3} alt="" />
                  </div>
                  <span>Deployment</span>
                </li>
                <li>
                  <div className="p-tab-ico">
                    <img src={img4} alt="" />
                  </div>
                  <span>Support and Maintenance</span>
                </li>
              </ul>
            </div>
            <div className="process-tabs-cntnt">
              <div className="p-tab-cntnt active">
                <div className="p-tab-c1">
                  <span>01</span>
                  <p>
                    At this crucial stage, we believe in the power of dialogue.
                    We take the time to ask targeted questions, allowing us to
                    gain a clear understanding of your business. Our goal is to
                    gather comprehensive insights that form the foundation of a
                    web design and development strategy tailored to your vision
                    and business goals.
                  </p>
                </div>
                <div className="p-tab-c2">
                  <img src={tabsweb1} alt="" />
                </div>
              </div>
              <div className="p-tab-cntnt">
                <div className="p-tab-c1">
                  <span>02</span>
                  <p>
                    Once the vision is crystalized, our seasoned developers set
                    out to translate your concepts into a dynamic and visually
                    engaging website. Leveraging state-of-the-art technologies
                    and coding expertise, we guarantee that your website not
                    only meets but surpasses industry benchmarks, delivering a
                    seamless user experience to your target audience.
                  </p>
                </div>
                <div className="p-tab-c2">
                  <img src={tabsweb2} alt="" />
                </div>
              </div>
              <div className="p-tab-cntnt">
                <div className="p-tab-c1">
                  <span>03</span>
                  <p>
                    During this stage, we breathe life into your website by
                    launching it onto a live server. Thorough testing guarantees
                    a seamless transition, and our deployment procedures are
                    meticulous, ensuring that your audience encounters the
                    complete functionality and design brilliance of your fresh
                    website.
                  </p>
                </div>
                <div className="p-tab-c2">
                  <img src={tabsweb3} alt="" />
                </div>
              </div>
              <div className="p-tab-cntnt">
                <div className="p-tab-c1">
                  <span>04</span>
                  <p>
                    Our dedication extends beyond deployment. We offer
                    continuous support and maintenance to uphold peak
                    performance for your website. Whether it's troubleshooting
                    unforeseen challenges of integrating updates and
                    enhancements, our team is steadfast in securing the
                    sustained success and optimal operation of your website in
                    the long run.
                  </p>
                </div>
                <div className="p-tab-c2">
                  <img src={tabsweb4} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default WDTabs;
