import React, { useEffect, useRef } from "react";
import img1 from "../../../Assets/Images/gallary.png";
import { Link } from "react-router-dom";
import { motion, useAnimationFrame } from "framer-motion";
import "animate.css/animate.compat.css";
import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.min.css";
import GallaryData from "../Data/GallryData";
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
const GallaryIndex = () => {
  const ref = useRef(null);
  useAnimationFrame((t) => {
    const y = (1 + Math.sin(t / 500)) * -10;
    ref.current.style.transform = `translateY(${y}px)`;
  });

  useEffect(() => {
    Fancybox.bind("[data-fancybox]", {
      infinite: true,
      transitionEffect: "fade",
      buttons: ["zoom", "slideShow", "fullScreen", "close"],
      autoFocus: false,
      backFocus: false,
      animationEffect: "fade",
      clickContent: function (current, event) {
        return current.type === "image" ? "zoom" : false;
      },
    });
  }, []);

  return (
    <div>
      <section className="inner-banner-sec">
        <div className="container">
          <div className="inner-banner-row">
            <div className="inner-banner-col1">
              <motion.h1
                initial={{ x: -400, opacity: "0" }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.6 }}
              >
                Our Gallery
              </motion.h1>
              <motion.p
                initial={{ x: -400, opacity: "0" }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.9 }}
              >
                Uniting Visionaries, One Masterpiece at a Time: Welcome to the
                Heart of Our Team Gallery.
              </motion.p>
              <motion.div
                initial={{ x: -400, opacity: "0" }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 1.3 }}
              >
                <Link
                  to="https://calendly.com/awais-xkw/website-design-development-consultation-with-client?month=2023-12"
                  target="__blank"
                >
                  Get Free Proposal
                </Link>
              </motion.div>
            </div>
            <motion.div
              className="inner-banner-col2"
              drag
              dragConstraints={{ left: 10, top: 10, right: 10, bottom: 10 }}
              style={{ cursor: "pointer" }}
              ref={ref}
            >
              <img src={img1} alt="" />
            </motion.div>
          </div>
        </div>
      </section>

      <section className="gallary-sec">
        <div className="container">
          <ScrollAnimation animateIn="fadeIn">
            <div className="industry-sec-title">
              <h2>Memories Made in Teqnite</h2>
              <p>
                Uniting Visionaries, One Masterpiece at a Time: Welcome to the
                Heart of Our Team Gallery.
              </p>
            </div>
          </ScrollAnimation>
          <div className="Gallary-sec-grid">
            {GallaryData.map((item, index) => (
              <ScrollAnimation animateIn="fadeIn" key={index}>
                <div className="galllary_item">
                  <div className="g__Images">
                    <Link to="javascript:;">
                      <img
                        src={item.Img}
                        alt=""
                        data-fancybox="inner-gallery"
                      />
                    </Link>
                  </div>
                </div>
              </ScrollAnimation>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default GallaryIndex;
