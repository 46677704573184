import techimg1 from "../Assets/Images/svs1-t1.png";
import techimg2 from "../Assets/Images/svs1-t2.png";
import techimg3 from "../Assets/Images/svs1-t3.png";
import techimg4 from "../Assets/Images/svs1-t4.png";
import techimg5 from "../Assets/Images/svs3-t1.png";
import techimg6 from "../Assets/Images/svs3-t2.png";
import techimg7 from "../Assets/Images/svs3-t3.png";
import techimg8 from "../Assets/Images/svs2-t1.png";
import techimg9 from "../Assets/Images/svs2-t2.png";
import techimg10 from "../Assets/Images/svs2-t3.png";
import techimg11 from "../Assets/Images/svs2-t4.png";
import techimg12 from "../Assets/Images/svs4-t1.png";
import techimg13 from "../Assets/Images/svs4-t2.png";
import techimg14 from "../Assets/Images/svs4-t3.png";
import techimg15 from "../Assets/Images/svs5-t1.png";
import techimg16 from "../Assets/Images/svs5-t2.png";
import techimg17 from "../Assets/Images/svs5-t3.png";
import techimg18 from "../Assets/Images/svs5-t4.png";
import techimg19 from "../Assets/Images/svs6-t1.png";
import techimg20 from "../Assets/Images/svs6-t2.png";
import techimg21 from "../Assets/Images/svs6-t3.png";
import techimg22 from "../Assets/Images/svs6-t4.png";

const ServicesData = [
  {
    id: 1,
    title: "Graphic Design",
    technologie: {
      title1: "Illustrator",
      title2: "Photoshop",
      title3: "Figma",
      title4: "Xd",
    },
    techimg: {
      tech1: techimg1,
      tech2: techimg2,
      tech3: techimg3,
      tech4: techimg4,
    },
    path: "/graphic-design",
    desc: "Our designs are appealing and eye-catching to drive sales and engagement for your business. We convert clicks to customers with stunning visuals.",
  },
  {
    id: 2,
    title: "Web Development",
    technologie: {
      title1: "Magento",
      title2: "Shopify",
      title3: "PHP",
      title4: "Wordpress",
    },
    techimg: {
      tech1: techimg8,
      tech2: techimg9,
      tech3: techimg10,
      tech4: techimg11,
    },
    path: "/web-development",
    desc: "Attract, engage, and convert with powerful websites. Get Your website flawlessly designed and developed to fuel your digital marketing.",
  },
  {
    id: 3,
    title: "App Development",
    technologie: {
      title1: "Iphone",
      title2: "Android",
      title3: "Cross Platform",
    },
    techimg: {
      tech1: techimg5,
      tech2: techimg6,
      tech3: techimg7,
    },
    path: "/app-development",

    desc: "Our sleek design and innovation expertise empower us to create apps that deliver your vision. We make your brand an app icon, impossible to ignore.",
  },
  {
    id: 4,
    title: "DevOps",
    technologie: {
      title1: "AWS",
      title2: "Azure",
      title3: "Digital Ocean",
    },
    techimg: {
      tech1: techimg12,
      tech2: techimg13,
      tech3: techimg14,
    },
    path: "/web-development",

    desc: "Experience Teqnite DevOps magic; streamline, automate, innovate. We provide top-tier DevOps services so you can enjoy high-velocity deployments with rock-solid stability.",
  },
  {
    id: 5,
    title: "Digital Marketing",
    technologie: {
      title1: "Google Awards",
      title2: "Ahref",
      title3: "MOZ",
      title4: "Semrush",
    },
    techimg: {
      tech1: techimg15,
      tech2: techimg16,
      tech3: techimg17,
      tech4: techimg18,
    },
    path: "/digital-marketing",

    desc: "If you wish to grow your brand effortlessly, Teqnite is your top digital marketing agency that powerfully ignites your online presence.",
  },
  {
    id: 6,
    title: "2D & 3D Animations",
    technologie: {
      title1: "After Effects",
      title2: "Maya",
      title3: "Animate",
      title4: "Moho",
    },
    techimg: {
      tech1: techimg19,
      tech2: techimg20,
      tech3: techimg21,
      tech4: techimg22,
    },
    path: "/2d-3d-animation",

    desc: "From 2D charm to 3D depth, we deliver impact. We create stories that move and have results that amaze! It's engaging content that entertains and converts!",
  },
];

export default ServicesData;
