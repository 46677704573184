import React, { useEffect } from "react";
import img1 from "../../../Assets/Images/tgd-icon-1.svg";
import img2 from "../../../Assets/Images/tgd-icon-2.svg";
import img3 from "../../../Assets/Images/tgd-icon-3.svg";
import img4 from "../../../Assets/Images/tgd-icon-4.svg";
import img5 from "../../../Assets/Images/tgd-icon-5.svg";
import img6 from "../../../Assets/Images/tgd-icon-6.svg";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import { Fancybox } from "@fancyapps/ui";
import { Link } from "react-router-dom";
import DataWeb from "./Data/PortfolioData";

import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.min.css";
import GetFreeProposal from "../../../ShareButton/GetFreeProposal";

const PortfolioWhychoose = () => {
  useEffect(() => {
    Fancybox.bind("[data-fancybox]", {
      infinite: true,
      transitionEffect: "fade",
      buttons: ["zoom", "slideShow", "fullScreen", "close"],
      autoFocus: false,
      backFocus: false,
      animationEffect: "fade",
      clickContent: function (current, event) {
        return current.type === "image" ? "zoom" : false;
      },
    });
  }, []);
  return (
    <div>
      <section className="benefits-sec">
        <div className="container">
          <div className="benefits-sec-title">
            <ScrollAnimation animateIn="fadeIn">
              <h2>Why Choose Teqnite Web Design And Development Company?</h2>
              <p>
                Choose Teqnite for the best Website Design and Development
                Services— we combine creativity with technical excellence for an
                outstanding online presence.
              </p>
            </ScrollAnimation>
          </div>
          <div className="benefits-blocks-row">
            <div className="bnf-cell">
              <ScrollAnimation animateIn="fadeIn">
                <div className="bnf-ico">
                  <img src={img1} alt="" />
                </div>
                <span>Reliable Service. In House Team</span>
              </ScrollAnimation>
            </div>
            <div className="bnf-cell">
              <ScrollAnimation animateIn="fadeIn">
                <div className="bnf-ico">
                  <img src={img2} alt="" />
                </div>
                <span>Trusted by People Like You</span>
              </ScrollAnimation>
            </div>
            <div className="bnf-cell">
              <ScrollAnimation animateIn="fadeIn">
                <div className="bnf-ico">
                  <img src={img3} alt="" />
                </div>
                <span>Complete Technical Competency</span>
              </ScrollAnimation>
            </div>
            <div className="bnf-cell">
              <ScrollAnimation animateIn="fadeIn">
                <div className="bnf-ico">
                  <img src={img4} alt="" />
                </div>
                <span>Friendly & Cordial in Nature</span>
              </ScrollAnimation>
            </div>
            <div className="bnf-cell">
              <ScrollAnimation animateIn="fadeIn">
                <div className="bnf-ico">
                  <img src={img5} alt="" />
                </div>
                <span>Excellent Quality Delivered on Time</span>
              </ScrollAnimation>
            </div>
            <div className="bnf-cell">
              <ScrollAnimation animateIn="fadeIn">
                <div className="bnf-ico">
                  <img src={img6} alt="" />
                </div>
                <span>Effective & Continuous Communication</span>
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </section>

      <section className="inner-potfolio-sec">
        <div className="container">
          <div className="inner-port-title">
            <ScrollAnimation animateIn="fadeIn">
              <h2>Teqnite Web Design And Development Agency Portfolio</h2>
              <p>
                Teqnite Web Design and Development — where innovation meets
                flawless functionality in stunning websites
              </p>
            </ScrollAnimation>
          </div>
          <div className="tabs-sec-grid web-hov">
            {DataWeb.map((item, index) => (
              <div className="t-grid-col" key="index">
                <ScrollAnimation animateIn="fadeIn">
                  <img src={item.img} alt="" data-fancybox="inner-gallery" />
                </ScrollAnimation>
              </div>
            ))}
          </div>
          <div className="inner-port-cta">
            <GetFreeProposal />
          </div>
        </div>
      </section>
    </div>
  );
};

export default PortfolioWhychoose;
