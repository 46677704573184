import img1 from "../../Assets/Images/tst-boi.png";
import img2 from "../../Assets/Images/tst-p1.jpg";
import img3 from "../../Assets/Images/tst-p2.jpg";
import img4 from "../../Assets/Images/tst-p3.jpg";
const TestimonialDataTwo = [
  {
    id: 1,
    Title: "John Doe",
    Designation: "Owner Of Tiffney Star",
    Description:
      "Teqnite has been a game-changer for us as a digital marketing agency. Their tailored strategies and effective solutions have truly boosted our online presence. The level of client satisfaction speaks volumes, making them our top choice for all our marketing needs.",
    Img: img1,
  },
  {
    id: 2,
    Title: "Doe Mark",
    Designation: "CEO & Founder Softlest",
    Description:
      "Teqnite's Graphic Design Services are a game-changer! From captivating logos to immersive UI/UX, their creative expertise exceeded all our expectations. The team's dedication to perfection and attention to detail left us 100% satisfied. Our brand visuals now speak volumes, thanks to Teqnite's exceptional Graphic Design Services.",
    Img: img2,
  },
  {
    id: 3,
    Title: "Jankok",
    Designation: "Project Manger ",
    Description:
      " Teqnite's Explainer Animation Services truly impressed us. Their ability to turn complex ideas into visually engaging narratives exceeded our expectations. The animations not only captivated our audience but also left a lasting impression, elevating our communication strategy. Teqnite's expertise in Explainer Animation makes them our trusted partner for simplifying and enhancing our brand's message. We're delighted with the profound impact these animations have had on our audience engagement.",
    Img: img3,
  },
  {
    id: 4,
    Title: "Mickel",
    Designation: "CEO & Founder Wingo",
    Description:
      " Teqnite exceeded expectations, delivering impeccable service and empowering us with source code transparency. Their one-window solution streamlined our experience, showcasing the unmatched expertise of their experienced staff. Choosing Teqnite meant choosing a dedicated partner for success – a decision we'd make again without hesitation.",
    Img: img4,
  },
];

export default TestimonialDataTwo;
