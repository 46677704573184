import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
const NotFoundComponent = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/");
  }, []);

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          height: "70vh",
          alignItems: "center",
        }}
      >
        <h2
          style={{
            fontSize: "4rem",
            color: "#ccc",
            textAlign: "center",
          }}
        >
          404 <br /> PAGE NOT FOUND
        </h2>
      </div>
    </div>
  );
};

export default NotFoundComponent;
