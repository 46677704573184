import React from "react";
import Main from "../BrandingMain/Main";
import Testimonial from "../../../Testimonial/Testimonial";
import GrowYourBusiness from "../../../Growbussnies/GrowYourBusiness";
import { Helmet } from "react-helmet";

const Index = () => {
  return (
    <div>
      <Helmet>
        <title>Full Branding Services for Your Business | Teqnite®</title>
        <meta
          name="description"
          content="Transform your business identity with Teqnite's comprehensive branding services. From logos to brand strategy, we craft a unique narrative for your success."
        />
        <meta
          name="keywords"
          content="Top branding agency , branding company, branding services, business branding services, branding design services, custom branding designs, branding company, brand identity design, digital branding"
        />
      </Helmet>
      <Main />
      <GrowYourBusiness />
      <Testimonial />
    </div>
  );
};

export default Index;
