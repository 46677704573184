import React, { useRef, useState } from "react";
import img1 from "../../../Assets/Images/blogs-bnr.png";
// import img2 from "../../../Assets/Images/featured-img-min.jpg";
import { Link, useParams } from "react-router-dom";
import { motion, useAnimationFrame } from "framer-motion";
import BlogData from "../../BlogPage/BlogData/Data";

const BlogInnerMain = () => {
  const { id } = useParams();
  const ref = useRef(null);
  useAnimationFrame((t) => {
    const y = (1 + Math.sin(t / 500)) * -20;
    ref.current.style.transform = `translateY(${y}px)`;
  });

  const blogPost = BlogData.find((BlogData) => BlogData.id === parseInt(id));

  if (!blogPost) {
    return (
      <div className="article-title">
        <h2 style={{ textAlign: "center", padding: "30px" }}>
          Blog Post Not Found
        </h2>
      </div>
    );
  }

  const { Img, Date, Title, Discriptions } = blogPost;

  return (
    <div>
      <section className="inner-banner-sec">
        <div className="container">
          <div className="inner-banner-row">
            <div className="inner-banner-col1">
              <motion.h1
                initial={{ x: -400, opacity: "0" }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.6 }}
              >
                Our Blogs
              </motion.h1>
              <motion.p
                initial={{ x: -400, opacity: "0" }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.9 }}
              >
                We are the storytellers of tomorrow - explicitly changing how
                you communicate your story
              </motion.p>
              <motion.div
                initial={{ x: -400, opacity: "0" }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 1.3 }}
              >
                <Link>Get Free Proposal</Link>
              </motion.div>
            </div>
            <motion.div
              className="inner-banner-col2"
              drag
              dragConstraints={{ left: 10, top: 10, right: 10, bottom: 10 }}
              style={{ cursor: "pointer" }}
              ref={ref}
            >
              <img src={img1} alt="" />
            </motion.div>
          </div>
        </div>
      </section>
      <section className="blog-inner-sec">
        <div className="container">
          <div className="blog-inner-grid">
            <article className="main-article" key={id}>
              <div className="featured-image">
                <img src={Img} alt="" />
              </div>
              <ul className="article-tags">
                <li>{Date}</li>
              </ul>
              <div className="article-title">
                <h2>{Title}</h2>
              </div>
              <div className="article-cntnt">
                <p>{Discriptions}</p>
              </div>
            </article>

            <div className="recent-blogs-col">
              <div className="rec-search-bar">
                <form>
                  <input type="search" name="" id="" placeholder="Search" />
                  <button type="submit"></button>
                </form>
              </div>
              <div className="recent-posts-col">
                <h3>Recent Posts</h3>
                <div className="rec-posts-grid">
                  {BlogData.slice(0, 5).map((item, index) => (
                    <div className="rec-post-cell" key={index}>
                      <Link to={`/blogInner/${item.id}`}>
                        <div className="rec-p-img">
                          <img src={item.Img} alt="" />
                        </div>
                        <div className="rec-p-info">
                          <div className="tags">
                            <li>{item.Date}</li>
                          </div>
                          <p>{item.Title.slice(0, 30)}...</p>
                        </div>
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default BlogInnerMain;
