import React from "react";
import TestiSliderOne from "./TestiSliderOne";
import TestiSliderTwo from "./TestiSliderTwo";
const Testimonial = () => {
  return (
    <div>
      {/* <section className="reviews-sec">
        <div className="container">
          <div className="reviews-sec-title">
            <h2>Over 1200+ Satisfied Clients and Growing</h2>
          </div>
          <div className="reviews-sec-row">
            <div className="rev-video-col">
              <TestiSliderOne />
            </div>
            <div className="rev-testimonial-col">
              <TestiSliderTwo />
            </div>
          </div>
        </div>
      </section> */}
    </div>
  );
};

export default Testimonial;
