import React from "react";
import Banner from "../Banner/Banner";
import Operations from "../AdaptableOperations/Operations";
import Services from "../ServicesWe/Services";
import Whychooseus from "../WhyChooseUs/Whychooseus";
import Portfolio from "../Portfolio/Portfolio";
import Industries from "../Industries/Industries";
import GrowYourBusiness from "../Growbussnies/GrowYourBusiness";
import OurOffice from "../Office/OurOffice";
import Testimonial from "../Testimonial/Testimonial";
import { Helmet } from "react-helmet";

const Index = () => {
  return (
    <div>
      <Helmet>
        <title>Teqnite® Digital Marketing Agency</title>
        <meta
          name="description"
          content="Teqnite: Your Digital Marketing Solution! Unlock success with innovative strategies, covering web design, development, SEO, logo design, and more. Consult now!"
        />
        <meta
          name="keywords"
          content="web design services, web development services, best web developers, Digital marketing agency, Internet marketing agency, Digital marketing strategies, Top digital marketing agency, Digital marketing campaigns, Digital marketing solutions"
        />
        <meta name="author" content="Teqnite®" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      <Banner />
      <Operations />
      <Services />
      <Whychooseus />
      <Portfolio />
      <Industries />
      <GrowYourBusiness />
      <OurOffice />
      <Testimonial />
    </div>
  );
};

export default Index;
