import React from "react";
import techimg1 from "../../../Assets/Images/d-tech-1.svg";
import techimg2 from "../../../Assets/Images/d-tech-2.svg";
import techimg3 from "../../../Assets/Images/d-tech-3.svg";
import techimg4 from "../../../Assets/Images/d-tech-4.svg";
import techimg5 from "../../../Assets/Images/d-tech-5.svg";
import techimg6 from "../../../Assets/Images/d-tech-6.svg";
import techimg7 from "../../../Assets/Images/d-tech-7.svg";
import techimg8 from "../../../Assets/Images/d-tech-8.svg";

const Technologies = () => {
  return (
    <div>
      <section class="dev-tech-sec">
        <div class="container">
          <div class="dev-tech-row">
            <div class="dev-tech-cell">
              <img src={techimg1} alt="" />
            </div>
            <div class="dev-tech-cell">
              <img src={techimg2} alt="" />
            </div>
            <div class="dev-tech-cell">
              <img src={techimg3} alt="" />
            </div>
            <div class="dev-tech-cell">
              <img src={techimg4} alt="" />
            </div>
            <div class="dev-tech-cell">
              <img src={techimg5} alt="" />
            </div>
            <div class="dev-tech-cell">
              <img src={techimg6} alt="" />
            </div>
            <div class="dev-tech-cell">
              <img src={techimg7} alt="" />
            </div>
            <div class="dev-tech-cell">
              <img src={techimg8} alt="" />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Technologies;
