import React from "react";
import ProjectMain from "../ProjectMain/ProjectMain";
import GrowYourBusiness from "../../../Growbussnies/GrowYourBusiness";
import Testimonial from "../../../Testimonial/Testimonial";
import { Helmet } from "react-helmet";

const Index = () => {
  return (
    <div>
      <Helmet>
        <title>Our Projects | Teqnite®</title>
        <meta
          name="description"
          content="Explore our impressive portfolio. Discover the excellence and innovation behind our projects, showcasing our commitment to delivering top-tier solutions."
        />
        <meta
          name="keywords"
          content="Digital marketing services, Digital marketing agency, Internet marketing agency, Digital marketing strategies, Digital marketing solutions"
        />
      </Helmet>
      <ProjectMain />
      <GrowYourBusiness />
      <Testimonial />
    </div>
  );
};

export default Index;
