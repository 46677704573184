import img1 from "../../Assets/Images/vid-boi-1.jpg";
import img2 from "../../Assets/Images/vid-boi-2.jpg";
import img3 from "../../Assets/Images/vid-boi-3.png";
import img4 from "../../Assets/Images/vid-boi-4.png";
import img5 from "../../Assets/Images/vid-boi-5.png";
import img6 from "../../Assets/Images/vid-boi-6.png";
import img7 from "../../Assets/Images/vid-boi-7.png";
import img8 from "../../Assets/Images/vid-boi-8.png";
import vid1 from "../../Assets/videos/sample.mp4";

const DataTestimonialOne = [
  {
    id: 1,
    img: img1,
    Video: vid1,
  },
  {
    id: 2,
    img: img2,
    Video: vid1,
  },
  {
    id: 3,
    img: img3,
    Video: vid1,
  },
  {
    id: 4,
    img: img4,
    Video: vid1,
  },
  {
    id: 5,
    img: img5,
    Video: vid1,
  },
  {
    id: 6,
    img: img6,
    Video: vid1,
  },
  {
    id: 7,
    img: img7,
    Video: vid1,
  },
  {
    id: 8,
    img: img8,
    Video: vid1,
  },
];

export default DataTestimonialOne;
