import React from "react";
import "animate.css/animate.compat.css";
import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.min.css";
import IndustriesData from "../Data/IndustriesData";

const Industries = () => {
  return (
    <div>
      <section className="industry-sec">
        <div className="container">
          <ScrollAnimation animateIn="fadeIn">
            <div className="industry-sec-title">
              <h2>Industries We Serve</h2>
              <p>We Have Worked Across Multiple Industries</p>
            </div>
          </ScrollAnimation>
          <div className="industry-sec-grid">
            {IndustriesData.map((item, index) => (
              <ScrollAnimation animateIn="fadeIn" key={index}>
                <div className="industry-cell">
                  <div className="i-cell-ico">
                    <img src={item.Img} alt="" />
                  </div>
                  <h3>{item.title}</h3>
                </div>
              </ScrollAnimation>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Industries;
